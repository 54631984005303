import {Component, Input} from '@angular/core';
import {ROUTER_DIRECTIVES} from '@angular/router';
import {TranslatePipe} from 'ng2-translate';

import {RestService} from "../../services/restCalls";
import {DataFilterService} from "../../services/dataFilters";

@Component({
	selector: 'athlete-search-list',
    template: `
		<div #content class="as_scroll_region" (window:resize)="onResize($event)">
			<ul>
				<li *ngFor="let item of filteredList">
					<a [routerLink]="['/athlete',item.athlete_id]">{{item.complete_name}}</a>
				</li>
			</ul>
		</div>
    `,
    directives: [ROUTER_DIRECTIVES],
    pipes: [TranslatePipe],
    styles: [`
	    .as_scroll_region {
	    	overflow: scroll;
	    	width: 100%;
	    	height: 100%;
	    	padding: 0 20px 60px 20px;
	    }
	
	    .as_scroll_region ul {
	    	list-style-type: none;
	    	margin:0;
	    	padding:0;
	    }

	    .as_scroll_region li {
	    	display:inline-block;
	    	width:250px;
	    }
    `]
})
export class AthleteSearchList {
	private filteredList:any[] = [];
	
	constructor( 
			private _restService:RestService,
			private _dataFilters:DataFilterService
	) {}
	
	ngOnDestroy() {
		this.filteredList = [];
	}
	
	public filterAthletes( list:any[], useYear=false ) {
		if( list ) {
			let male:boolean = this._dataFilters.male;
			let female:boolean = this._dataFilters.female;
			let from:number = this._dataFilters.from;
			let to:number = this._dataFilters.to;
			this.filteredList = [];
			for( var i=0; i<list.length; i++ ) {
				var item = list[i];
				if ( (male && item.gender == 1) || (female && item.gender == 0) ) {
					if ( !useYear || (item.minYear <= to && item.maxYear >= from) ) {
						this.filteredList.push( item );
					}
				}
			}
			this._restService.hackRefresh();
		} else console.log( 'list undefined');
	};
	
	flag:boolean = true;		// connect to settings to show name as 'first last' or 'last, first'
	showName( item:any ) {	// turn into filter connected to settings
		return this.flag ? item.complete_name : item.display_name;
	}
}