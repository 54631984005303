import { provideRouter, RouterConfig } from '@angular/router';

import {HomeComponent} from './home';
import {ArticlesComponent} from './media/articles';
import {BooksComponent} from './media/books';
import {ImagesComponent} from './media/images';
import {MagazinesComponent} from './magazines/magazines';
import {MoviesComponent} from './media/movies';
import {FaqComponent} from './info/faq';
import {AckComponent} from './info/ack';
import {DisclaimerComponent} from './info/disclaimer';
import {LinksComponent} from './info/links';
import {SummaryComponent} from './stats/summary';
import {TopAthletesComponent} from './stats/top';
import {ContestStatsComponent} from './stats/contests';
import {AthleteStatsComponent} from './stats/athletes';
import {EventFrequencyComponent} from './stats/event';
import {DonateComponent} from './info/donate';

import {AthleteSearchComponent} from './athletes/athleteSearch';
import {AthleteAlphaListComponent} from './athletes/athleteAlphaList';
import {AthleteHistoryComponent} from './athletes/athleteHistory';

import {ContestsByOrgComponent} from './contests/contestsByOrg';
import {ContestsInYearComponent} from './contests/contestsInYear';
import {ContestByYearsComponent} from './contests/contestByYears';
import {ContestAllYearsComponent} from './contests/contestsAllYears';
import {ContestResultsComponent} from './contests/contestResults';

import {MagCoverComponent} from './magazines/magCover';
import {MagListComponent} from './magazines/magList';
import {ArticleComponent} from './media/article';

export const routes:RouterConfig = [
	{ path: "/", component: HomeComponent, index:true },
	{ path: "/articles", component: ArticlesComponent },
	{ path: "/books", component: BooksComponent },
	{ path: "/images", component: ImagesComponent },
	{ path: "/magazines", component: MagazinesComponent },
	{ path: "/movies", component: MoviesComponent },
	{ path: "/faq", component: FaqComponent },
	{ path: "/ack", component: AckComponent },
	{ path: "/disclaimer", component: DisclaimerComponent },
	{ path: "/links", component: LinksComponent },
	{ path: "/stats/summary", component: SummaryComponent },
	{ path: "/stats/top", component: TopAthletesComponent },
	{ path: "/stats/contest", component: ContestStatsComponent },
	{ path: "/stats/athletes", component: AthleteStatsComponent },
	{ path: "/stats/frequency", component: EventFrequencyComponent },
	{ path: "/donate", component: DonateComponent },
	{ path: "/admin", component: DonateComponent },
	
	{ path: "/athlete/search/:match", component: AthleteSearchComponent },
	{ path: "/athlete/search/:match/:country_code", component: AthleteSearchComponent },
	{ path: "/athlete/alpha/:alpha", component: AthleteAlphaListComponent },
	{ path: "/athlete/:athlete_id", component: AthleteHistoryComponent },
			
	{ path: "/contests/org/:org_id",component: ContestsByOrgComponent },
	{ path: "/contest/years/:contest_id", component: ContestByYearsComponent },
	{ path: "/contests/decades", component: ContestAllYearsComponent },
	{ path: "/contests/year/:year", component: ContestsInYearComponent },
	{ path: "/contest/:event_id", component: ContestResultsComponent },

	{ path: "/articles/:match", component: ArticlesComponent },
	{ path: "/article/item/:article_id", component: ArticleComponent },			// would like article/:article_id but conflicts with restful
	{ path: "/magazines/:mag_id", component: MagListComponent },
	{ path: "/mag_issue/:mag_issue_id", component: MagCoverComponent },
];

export const APP_ROUTER_PROVIDERS = [ provideRouter(routes) ];