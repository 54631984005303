import {Component,Input} from '@angular/core';
import {TranslatePipe} from 'ng2-translate';
import {Router} from '@angular/router';
import {FormatMonthPipe,PrefixStrPipe} from "../../pipes/formatting";

@Component({
    template: `<li>
    	<span (click)="showArticle(item.article_id)" [class]='{link2 : item.article_id}'>{{item.article_title}}</span>
    		, <i>{{item.mag_name}}</i>, {{item.month | formatMonth}} {{item.year}}{{item.page_num == 0 ? '' : item.page_num | prefixStr: ", Page "}}</li>
	`,
    pipes: [FormatMonthPipe, PrefixStrPipe],
})
export class AthleteArticle {
	@Input() item:any;
	
	constructor( private _router: Router ) {}
	
	showArticle( article_id:number ) {
		if( article_id > 0 ) {
			this._router.navigate( ['Article', {article_id:article_id} ] );
		}
	};
}