import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute,ROUTER_DIRECTIVES} from '@angular/router';
import {TranslatePipe} from 'ng2-translate';

import {RestService} from "../../services/restCalls";
import {DataFilterService} from "../../services/dataFilters";
import {FormatContestNamePipe} from "../../pipes/formatting";	

@Component({
    templateUrl: 'app/views/contests/contest_results.html',
    styles: [],
    directives: [ROUTER_DIRECTIVES],
    pipes: [TranslatePipe],
    
})
export class ContestHistory {
	event_id:number;
	subscription: any;
	list:any[] = [];
	filteredList:any[] = [];

	constructor( private _route:ActivatedRoute, private _restService:RestService, private _dataFilters:DataFilterService ) {}

	ngOnInit() {
		this._dataFilters.setFilters( true );
		
		let dataStream = this._route.params.map( (params) => { 
			this.event_id = +params['event_id'];
			return this.event_id;
		} )
		.flatMap( (event_id, i) => {
			return this._restService.getContestResults(event_id);
		} )
		.map( (list:any[]) => { 
			this.list = list; 
		} );

		this.subscription = this._dataFilters.filterChange$
			.merge(dataStream)
			.subscribe( () => {
				this.filterContests();
	       }
       );
	}
	
	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.list = null;
		this.filteredList = null;
	}
	
	private filterContests() {
		this.filteredList = this.list;
		this._restService.hackRefresh();
	}
}