import {Injectable, NgZone} from '@angular/core';
import {Http,Response,Headers} from '@angular/http';
import {Observable} from 'rxjs/Observable';
import {LoadingIconService} from "./loadingIconService";

	
@Injectable()
export class RestService {
	
	private _baseUrl:string = "http://athleticfit.com/";
	private _auth_token:string;
	
	constructor (private http: Http, private ngZone: NgZone, private loadingIcon:LoadingIconService ) {}

	get headers() {
		let headers = new Headers();
		headers.append( 'Content-Type', 'application/json' );
		if( this._auth_token ) {
			headers.append( "Authorization", "Bearer "+this._auth_token );
		}
		return { headers: headers };
	}
	
	private extractData(res: Response) {
		if (res.status < 200 || res.status >= 300) {
			throw new Error('Bad response status: ' + res.status);
		}
		let body = res.json();
		return body.data || { };
	}
		
	private handleError (error: any) {
		let errMsg = error.message || 'Server error';
		console.error(errMsg); // log to console instead
		return Observable.throw(errMsg);
	}
	
	fetch(url:string) {
		return this.http.get( this._baseUrl+url, this.headers )
                  .map(this.extractData);
//                  .catch(this.handleError);
	}
	
	post( url:string, obj:any, doCatch=true ) {
		let stream = this.http.post( this._baseUrl+url, JSON.stringify(obj), this.headers )
					.map( this.extractData );
		if( doCatch ) stream.catch(this.handleError);
		return stream;
	}
	
	del( url:string ) {
		return this.http.delete( this._baseUrl+url, this.headers )
				.map( this.extractData )
				.catch(this.handleError);
	}

	login( username:string, password:string ) {
		return this.post( "auth/login", { username:username, password:password } )
			.map( res =>{ 
				this._auth_token = res.auth_token;
				return res;
			});
	}

	changePassword( old_password:string, new_password:string ) {		// user_id comes from auth token
		return this.post( "auth/password", { old_password:old_password, new_password:new_password } );
	}
	
	load( url:string ) {
		this.loadingIcon.show();
		return this.fetch( url ).map( (res) => { this.loadingIcon.hide(); return res; } );
	}
	
	
	getParams() {
		return this.load("params");
	}
	
	getContestsByOrg(id:number) {
		return this.load( "contests/org/"+id );
	}
	
	getContestsInYear(year:number) {
		return this.load( "contests/year/"+year );		
	}
	
	getContestYears(id:number) {
		return this.load( "contest/years/"+id );
	}
	
	getContestResults(id:number) {
		return this.load( "contest/results/"+id );
	}
	
	getAthleteSearch( match:string, country:string = null ) {
		if( match == '' ) match='*';
		let url:string = "athletes/list/"+match;
		if( country != null ) url += "/" + country;
		return this.load( url );
	}
	
	getAthletesByAlpha( alpha:string ) {
		return this.load( "athletes/alpha/"+alpha );
	}
	
	getAthleteHistory( athlete_id:any ) {
		return this.load( "athlete/"+athlete_id );
	}
	
	getMagSummary() {
		return this.load( "mags/summary" );
	}
	
	getMags( mag_id:number ) {
		return this.load( "mags/"+mag_id );
	}
	
	getMagIssue( mag_issue_id:number ) {
		return this.load( "mags/issue/"+mag_issue_id );
	}
	
	getArticle( article_id:number ) {
		return this.load( 'article/'+article_id );
	}
	
	searchArticles( match:string ) {
		if( match == null || match.length == 0 ) {
			return Observable.empty().startWith( [] );
		} else {
			return this.load( 'articles/'+match );
		}
	}
	
	getAthleteCount() {
		return this.load( "statistics/athletes" )
			.map( (data) => { 
				return this.parseStats(data, ['men','classic','women','figure','physique'] );
			});
	}
	
	getAthleteFrequency() {
		return this.load( "statistics/frequency" )
			.map( (data) => { 
				return this.parseStats(data, ['men','women']);
			});
	}
	
	getContestCount() {
		return this.load( "statistics/contests" )
			.map( (data) => { 
				return this.parseStats(data, ['men','women']);
			});
	}

	private parseStats( data,tags ) {
		for( var j=0; j<tags.length; j++ ) {
			var tag = tags[j];
			for( var i=0; i<data.length; i++ ) {
				if( !data[i].hasOwnProperty( tag ) ) data[i][tag] = 0;
			}
		}
		return data;
	}
	
	fetchMovies() {
		return this.load( 'movies' );
	}
	
	fetchLinks() {
		return this.load( 'links2' );
	}
	
	hackRefresh() {
		this.ngZone.run( ()=>{} );
	}
}