import {Injectable, provide, Provider} from '@angular/core';

var MyConfig = {
	languages: [
	    { value: "en", label: "English" },
	    { value: "es", label: "Español" },
	    { value: "pt", label: "Português" },
	    { value: "fr", label: "Française" },
	    { value: "de", label: "Deutsch" },
	    { value: "it", label: "Italiana" },
	    { value: "hu", label: "Magyar" },
	    { value: "el", label: "Ελληνική" },
	    { value: "ru", label: "Русский" },
	    { value: "ja", label: "日本語" },
	    { value: "ko", label: "한국어" },
	    { value: "zh_CN", label: "简体中文" },
	    { value: "zh_TW", label: "中国" },
	    { value: "ar", label:"عربي" }
	]
};

export var MYCONFIG_PROVIDER:Provider[] = [
  provide(MyConfig, {useValue: MyConfig})
];