import {Component} from '@angular/core';
import {TranslatePipe} from 'ng2-translate';
import {LoadingIconService} from "../../services/loadingIconService";

@Component({
	selector: 'loading-icon',
    template: `
    	<div class='loading-icon' *ngIf="loadingIconService.visible">
			<img src="/assets/loading.gif">
		</div>
	`,
    pipes: [TranslatePipe],
    styles: [`
        .loading-icon {
    		position: absolute;
    		top:0;
    		bottom:0;
    		left: 0;
    		right:0;
    		background-color: rgba(0,0,0,0.1);
	    }
    	.loading-icon img {
    		position: absolute;
    		width: 106px;
    		height: 107px;
    		top: 0;
    		bottom: 0;
    		left: 0;
    		right: 0;
    		margin: auto;
    	}
    	.loading-icon img {
    		
    	}
    `]
})
export class LoadingIconComponent {
	constructor( public loadingIconService:LoadingIconService ) {}
}
