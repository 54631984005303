import {Component} from '@angular/core';
import {ActivatedRoute,ROUTER_DIRECTIVES} from '@angular/router';
import {TranslatePipe} from 'ng2-translate';

import {DataFilterService} from "../../services/dataFilters";
import {RestService} from "../../services/restCalls";

import {AthleteInfo} from "./history/athleteInfo";
import {AthleteBio} from "./history/athleteBio";
import {AthleteContestHistory} from "./history/athleteContestHistory";
import {AthleteMags} from "./history/athleteMags";
import {AthleteArticles, AthleteAuthoredArticles} from "./history/athleteArticles";

@Component({
	template:`
		<div *ngIf='ready' class='athlete-history'>
			<h2>{{rec.athlete.display_name}}</h2>
 			<athlete-info [history]="rec"></athlete-info>
			<athlete-bio [bio]="rec.bio"></athlete-bio>
			<athlete-contest-history [contests]="rec.contests"></athlete-contest-history>
			<athlete-mags [mags]="rec.mags"></athlete-mags>
			<athlete-authored-articles [authored]="rec.authored" [display-name]="rec.athlete.display_name"></athlete-authored-articles>
			<athlete-articles [articles]="rec.articles" [display-name]="rec.athlete.display_name"></athlete-articles>
			<image-viewer [visible]="isImgViewerVisible" [images]="imageBuffer"></image-viewer>
		</div>
	`,
	styles: [`
		.athlete-history {
			max-width:1000px;
			margin: 0 auto;
		}
	`],
	directives: [
		AthleteInfo,
		AthleteBio,
		AthleteContestHistory,
		AthleteMags,
		AthleteArticles,
		AthleteAuthoredArticles,
//		ImageViewer
	],
	pipes: []
})
export class AthleteHistoryComponent {
	private subscription:any;
	private rec:any;
	private ready:boolean = false;
	private isImgViewerVisible:boolean;
	private imageBuffer:any[];
	
	constructor( 
		private _route:ActivatedRoute, 
		private _restService:RestService,
		private _dataFilters:DataFilterService
	) {}

	ngOnInit() { 
		this._dataFilters.setFilters();
		this.subscription = this._route.params
			.map( (params) => {
				return params['athlete_id'];
			} )
			.flatMap( (athlete_id, i) => { 
				this.ready = false;
				return this._restService.getAthleteHistory(athlete_id);
			} )
			.subscribe( (rec:any) => {
				this.rec = rec;
				this.ready = true;
				this._restService.hackRefresh();
			}
		);
	}
	
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}