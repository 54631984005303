import {Component,EventEmitter,Input,Output} from '@angular/core';
import {ParamsService} from "../../services/params";
	
@Component( {
	selector: 'org-selector',
	template: `<div>
		<select #sel (change)="_onChange(sel.value)">
			<option *ngFor="let org of paramsService.filteredOrgs(filter)" [value]="org.org_id">{{org.org_abbrev}}</option>
		</select>
	</div>`
})

export class OrgSelector {
	@Input() filter:string[];
	@Output() onChange = new EventEmitter<number>();
	
	selected:number;

	constructor( public paramsService:ParamsService ) {}
	
	private _onChange(value:number) {
		this.selected = value;
		this.onChange.emit(value)
	}
}