import {Component,Input} from '@angular/core';
import {TranslateService, TranslatePipe} from 'ng2-translate';
import {RestService} from "../../services/restCalls";

@Component({
	selector: 'movies-bodybuilder',
	template:`
		<dt>{{bodybuilder.name}}</dt>
		<dd>
			<ul>
				<li *ngFor="let movie of bodybuilder.list"><span
					(click)="showImdb(movie.imdb_id)" class='link'>{{movie.title}}</span>
					({{movie.year}})</li>
			</ul>
		</dd>
	`,
	styles: [`
	 	`],
})
export class MoviesBodybuilder {
	@Input() bodybuilder:any;
}

@Component({
    templateUrl: 'app/views/media/Movies.html',
    styles: [`
		.movies {
	    	position: fixed;
	    	top: 50px;
	    	bottom: 50px;
	    	right: 50px;
	    	left: 50px;
	    	overflow: hidden;
	    }
	
	    .movies-container {
	    	width: 100%;
	    	height: 100%;
	    	overflow: scroll;
	    	padding-bottom:150px;
	    	margin-top:8px;
	    }
	
	    .movies table {
	    	width:100%;
	    }
	
	    .movies tr {
	    	vertical-align: top;
	    }
	
	    .movies th {
	    	text-align: left;
	    }
   	`],
   	directives: [MoviesBodybuilder],
    pipes: [TranslatePipe]
})
export class MoviesComponent {
	activeTab:string = 'bb';
	private movies: any[];
	public bodybuilders: any[];

	public get moviesByYear(): any[] {
		return this.movies.sort( function(a,b) {
			let diff = a.year - b.year;
			return diff == 0 ? a.title.localeCompare( b.title ) : diff;
		});
	}
	public get moviesByTitle(): any[] {
		return this.movies.sort( function(a,b) {
			return a.title.localeCompare( b.title );
		});
	}

	constructor( private _restService:RestService ) {}

	changeTab( tab:string ) {
		this.activeTab = tab;
	}
	
	ngOnInit() {
		this._restService.fetchMovies().subscribe( (data) => this.sortData(data) );
	}
	
	private sortData( movies:any[] ) {
		this.movies = movies;
		this.bodybuilders = this.sortByBodybuilders(movies);
		this._restService.hackRefresh();
	}
	
	private sortByBodybuilders( movies:any[] ):any[] {
		var adjustName = function( name:string ) {
			var split = name.split( " " );
			var temp = split[0];
			split[0] = split[1];
			split[1] = temp;
			return split.join(' ');
		};
		var obj = {};
		for( let movie of movies ) {
			for( let origName of movie.stars ) {
				var name = adjustName( origName );
				if( !obj.hasOwnProperty( name ) ) {
					obj[name] = { sort:name, name:origName, list:new Array() };
				}
				obj[ name ].list.push( { title:movie.title, imdb_id:movie.imdb_id, year:movie.year} );
			}
			for( let origName of movie.bodybuilders) {
				var name = adjustName( origName );
				if( !obj.hasOwnProperty( name ) ) {
					obj[name] = { sort:name, name:origName, list:new Array() };
				}
				obj[ name ].list.push( { title:movie.title, imdb_id:movie.imdb_id, year:movie.year} );
			}
		}
		var array = new Array();
		for( var value in obj ) {
			array.push( obj[value] );
		}
		return array.sort( function( a, b ) {
			return a.sort.localeCompare( b.sort );
		});
	}
	
	showImdb( id:any ) {
		
	}
	
	showAthlete( name:string ) {
		
	}
	
	
}
