import {Component, OnInit} from '@angular/core';
import {ROUTER_DIRECTIVES} from '@angular/router';

import {LoadingIconService} from "../services/loadingIconService";
import {ParamsService} from "../services/params";
import {RestService} from "../services/restCalls";
import {DataFilterService} from "../services/dataFilters";

import {TopMenuComponent} from './menu';
import {LanguageSelector} from './widgets/languageSelector';
import {DataFilterDialog} from './widgets/dataFilterDialog';
import {LoadingIconComponent} from './widgets/loadingIcon';
import {CopyrightComponent} from './widgets/copyright';

@Component({
    selector: 'musmem-app',
    
    template: `
			<musmem-menu></musmem-menu>
			<div class='login-language-bar'>
				<musmem-filter></musmem-filter>
				<musmem-login></musmem-login>
				<language-selector></language-selector>
			</div>
			<div class='main-container'>
				<router-outlet></router-outlet>
			</div>
			<copyright></copyright>
			<loading-icon></loading-icon>
    `,
    styles: [`
        .login-language-bar {
         		position: absolute;
         		top: 0;
         		right: 0;
     		    float: right;
    			display: flex;
    			margin: 10px;
    	}
	    .main-container {
	    	border:solid thin gold;
	    	position: fixed;
	    	top: 50px;
	    	bottom: 50px;
	    	left:0;
	    	right:0;
	    	padding: 0 20px;
	    	overflow: scroll;
	    }
    `],
         
    directives: [ROUTER_DIRECTIVES, TopMenuComponent, LanguageSelector, DataFilterDialog, LoadingIconComponent, CopyrightComponent ],
    
    providers: [ParamsService, DataFilterService, RestService, LoadingIconService],
    

})



export class AppComponent {
    constructor() {}
}