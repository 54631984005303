import {Component} from '@angular/core';
import {TranslateService, TranslatePipe} from 'ng2-translate';

import {RestService} from "../../services/restCalls";
import {DataFilterService} from "../../services/dataFilters";

@Component({
    template:`
		<div class='stats-athletes'>
			<h3>{{"STATS.NUM_ATHLETES_EACH_YEAR"|translate}}</h3>
			<div class="stats_athletes_container">
				<linechart data="results" options="options"></linechart>
			</div>
			<slider ng-model-low="options.axes.x.min" ng-model-high="options.axes.x.max" 
				floor="{{_df.minYear}}" ceiling="{{_df.maxYear}}" buffer="1" step="1" />
			<p>{{"STATS.REMINDER"|translate}}</p>
		</div>
    `,
    styles: [`
		.stats_athletes_container {
	    	width: 100%;
	    	height: 600px;
	    	padding-bottom: 40px;
	    }
    `],
    directives: [],
    pipes: [TranslatePipe]
})
export class AthleteStatsComponent {
	private subscription: any;
	private results;
	private options;

	constructor( 
			private _restService:RestService,
			private _df:DataFilterService
	) {}

	
	private defineOptions() {
		this.options = {
				axes: { 
					x: { key:"year", tickFormat:function(d,i) { return d.toString(); }, min:this._df.minYear, max:this._df.maxYear }
				},
				series: [
				         { axis: "y", dataset:"ds", key:"total", label:"", type:["column"], color:'green' },
				         { axis: "y", dataset:"ds", key:"men", label:"", type:["column"], color:'blue' },
				         { axis: "y", dataset:"ds", key:"classic", label:"", type:["column"], color:'cyan' },
				         { axis: "y", dataset:"ds", key:"women", label:"", type:["column"], color:'red' },
				         { axis: "y", dataset:"ds", key:"figure", label:"", type:["column"], color:'brown' },
				         { axis: "y", dataset:"ds", key:"physique", label:"", type:["column"], color:'orange' }
				],
				margin: {top: 5}
		};
	}
	
	
	ngOnInit() {
		this.defineOptions();
		this.subscription = this._restService.getAthleteCount()
			.subscribe( (data) => {
					this.results = {ds: data};
			});
	}
	
	ngOnDestroy() {
		if( this.subscription ) this.subscription.unsubscribe();
		this.results = [];
	}
}
