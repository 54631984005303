import {Component} from '@angular/core';
import {ActivatedRoute,ROUTER_DIRECTIVES} from '@angular/router';
import {TranslatePipe} from 'ng2-translate';
import {RestService} from "../../services/restCalls";
import {DataFilterService} from "../../services/dataFilters";
import {FormatContestNamePipe} from "../../pipes/formatting";	

@Component({
    templateUrl: 'app/views/contests/contests_in_year.html',
    directives: [ROUTER_DIRECTIVES],
    pipes: [TranslatePipe],
    
})
export class ContestsInYearComponent {
	private subscription: any;
	private list:any[] = [];
	public filteredList:any[] = [];
	public year:number;
	
	constructor( private _route:ActivatedRoute, 
				private _restService:RestService, 
				private _dataFilters:DataFilterService ) {}

	ngOnInit() {
		this._dataFilters.setFilters( false, true, true );
		
		let dataStream = this._route.params.map( (params) => { 
			this.year = +params['year'];
			return this.year;
		} )
		.flatMap( (year, i) => {
			return this._restService.getContestsInYear(year);
		} )
		.map( (list:any[]) => this.storeList( list ) );

		this.subscription = this._dataFilters.filterChange$
			.merge(dataStream)
			.subscribe( () => {
				this.filterContests();
	       }
       );
	}
	
	ngOnDestroy() {
		if( this.subscription ) this.subscription.unsubscribe();
	}
	
	private storeList( list ) {
		this.list = list;
		this._dataFilters.setOrgFilters( list );
	}
	
	filterContests() {
		let male = this._dataFilters.male;
		let female = this._dataFilters.female;
		let org_id = this._dataFilters.org;
		if( org_id == undefined ) org_id = -1;
		this.filteredList = [];
		for( var i=0; i<this.list.length; i++ ) {
			var item = this.list[i];
			if( (male && item.male) || (female && item.female) ) {
				if( org_id < 0 || org_id == item.org_id ) {
					this.filteredList.push( item );
				}
			}
		}
		this._restService.hackRefresh();
	}
}