import {Component} from '@angular/core';
import {TranslatePipe} from 'ng2-translate';
	
@Component({
    template:`
<div class='faq'>
	<h4>{{"FAQ.TITLE"|translate}}</h4>
	<h5>{{"FAQ.SUBTITLE"|translate}}</h5>
	<div class='faq-container'>
		<div class='faq-content'>
			<p class="q">{{"FAQ.Q1"|translate}}</p>
			<p class="a">{{"FAQ.A1"|translate}}</p>
			<p class="q">{{"FAQ.Q2"|translate}}</p>
			<p class="a">{{"FAQ.A2a"|translate}}</p>
			<p class="a">{{"FAQ.A2b"|translate}}</p>
			<p class="q">{{"FAQ.Q3"|translate}}</p>
			<p class="a">{{"FAQ.A3"|translate}}</p>
			<p class="q">{{"FAQ.Q4"|translate}}</p>
			<p class="a">{{"FAQ.A4"|translate}}</p>
			<p class="q">{{"FAQ.Q5"|translate}}</p>
			<p class="a">{{"FAQ.A5"|translate}}</p>
			<p class="q">{{"FAQ.Q6"|translate}}</p>
			<p class="a">{{"FAQ.A6"|translate}}</p>
			<p class="q">{{"FAQ.Q7"|translate}}</p>
			<p class="a">{{"FAQ.A7"|translate}}</p>
			<p class="q">{{"FAQ.Q8"|translate}}</p>
			<p class="a">{{"FAQ.A8"|translate}}</p>
			<p class="q">{{"FAQ.Q9"|translate}}</p>
			<p class="a">{{"FAQ.A9"|translate}}</p>
			<p class="q">{{"FAQ.Q10"|translate}}</p>
			<p class="a">{{"FAQ.A10a"|translate}}</p>
			<p class="a">{{"FAQ.A10b"|translate}}</p>
		</div>
	</div>
</div>
    `,
    styles: [`
    	.faq-container {
	   		width: 100%;
			height: 100%;
			padding-bottom:100px;
			overflow: scroll;
    	}
	    .faq h4 { width: 700px; margin: 4px auto 10px auto; }
	    .faq h5 { width: 700px; margin: 4px auto 10px auto; }
    	.faq-content {
			width: 700px;
			margin: 0 auto;
    	}
		.faq .q {
			color: #700;
			font-size: 120%
		}
		
		.faq .a {
			margin-left: 5%;
			color: #007;
			text-align: justify
		}
    `],
    pipes: [TranslatePipe]
})
export class FaqComponent {}
