import {Component} from '@angular/core';
import {TranslateService, TranslatePipe} from 'ng2-translate';

@Component({
    templateUrl: 'app/views/stats/TopAthletes.html',
    pipes: [TranslatePipe]
})
export class TopAthletesComponent {

}
