import {Component,Input} from '@angular/core';
@Component( {
	selector: 'image-viewer',
	templateUrl: 'app/views/widgets/ImageViewer.html',
})

export class ImageViewer {
	@Input() images:any[];
	@Input() visible:boolean;
	
	hideImgViewer() {
		
	}
	
	showPrev() {
		
	}
	
	showNext() {
		
	}
}