import {Pipe, PipeTransform} from '@angular/core';

import {FormatMonthPipe} from "./formatting";


@Pipe({name: 'formatMagRange'})
export class FormatMagRangePipe implements PipeTransform {
	transform(item:any):string {
		if( item.year0 == item.year1 || item.year1 == 0 ) return "("+item.year0+")";
		if( item.year0 == 0 ) return "("+item.year1+")";
		return "("+item.year0+" - "+item.year1+")";	}
}

@Pipe({name: 'formatMagCount'})
export class FormatMagCountPipe implements PipeTransform {
	transform(item:any):string {
		if( item == null ) return '';
		var str = item.count+" magazine";
		if( item.count != 1 ) str += "s";
		str += ', '+item.scanned+" scanned";
		return str;
	}
}

@Pipe({name: 'formatMagVolNum'})
export class FormatMagVolNumPipe implements PipeTransform {
	transform(mag:any):string {
		if( mag == null ) {
			return '';
		} else if( mag.vol == 0 ) {
			return mag.num == 0 ? '' : "Issue "+mag.num;
		} else if( mag.num == 0 ) {
			return "Vol "+mag.vol;
		} else {
			return "Vol "+mag.vol+", Num "+mag.num;
		}
	}
}

@Pipe({name: 'formatMonthYear'})
export class FormatMonthYearPipe implements PipeTransform {
	transform(mag:any):string {
		if( mag == null ) return '';
		if( mag.month == 0 && mag.year == 0 ) return "unknown";
		let monthPipe = new FormatMonthPipe();
		return monthPipe.transform(mag.month) + ' '+ mag.year;
	}
}