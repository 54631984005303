import {Component,Input} from '@angular/core';
import {ActivatedRoute,ROUTER_DIRECTIVES} from '@angular/router';

import {TranslatePipe} from 'ng2-translate';

import {FormatMonthPipe} from "../../pipes/formatting";
import {FormatMagVolNumPipe} from "../../pipes/magPipes";

import {RestService} from "../../services/restCalls";

@Component({
    template:`
    <div *ngIf="mag" class="mag-issue">
    	<h3>{{mag.mag_name}}, {{mag | formatMagVolNum}}</h3>
    	<h5 *ngIf="mag.year != 0">{{mag.month | formatMonth}} {{mag.year}}</h5>
    	<img *ngIf="mag.image" [src]="mag_image" />
    	<div *ngIf="mag.toc">
    		<h4>{{"MAGAZINES.TABLE_OF_CONTENTS"|translate}}</h4>
    		<table>
    			<tr>
    				<th>{{"MAGAZINES.ARTICLE"|translate}}</th>
    				<th>{{"MAGAZINES.AUTHOR"|translate}}</th>
    				<th>{{"MAGAZINES.PAGE"|translate}}</th>
    			</tr>
    			<tr *ngFor="let toc of mag.toc">
    				<td style="max-width: 400px">
    					<a *ngIf="toc.online == 1" [routerLink]="['article',toc.article_id]">{{toc.article_title}}</a>
    					<span *ngIf="toc.online == 0">{{toc.article_title}}</span>
    				</td>
					<td>
						<template *ngIf="parseAthletes(toc.athletes)" ngFor let-athletes [ngForOf]="athletes" let-i="index">
							<a [routerLink]="['/athlete',athletes]">{{athletes}}{{i+1 < len ? ', ' : ''}}</a>
						</template>
					</td>
	   				<td>{{toc.page_num}}</td>
    			</tr>
    		</table>
    	</div>
    	<a class='text-center' *ngIf="mag.pdf" href='{{mag_pdf}}'>PDF</a>
    `,
    styles: [`
        .mag-issue {
    		max-width: 1000px;
    		margin: 0 auto;
	    }
    	.mag-issue > *:after {
    		padding-bottom: 20px;
    	}
    	.mag-issue td { padding-right:20px; }
    `],
    directives: [ROUTER_DIRECTIVES],
    pipes: [TranslatePipe, FormatMagVolNumPipe, FormatMonthPipe]
})
export class MagCoverComponent {
	private subscription:any;
	private mag:any;
	private mag_image:string;
	private mag_pdf:string;
	private athletes:string[];
	private len:number = 0;
	
	constructor( 
		private _route:ActivatedRoute, 
		private _restService:RestService
	) {}
	
	ngOnInit() {
		this.subscription = this._route.params
		.map( (params) => {
			return +params['mag_issue_id'];
		} )
		.flatMap( (mag_issue_id, i) => { 
			return this._restService.getMagIssue(mag_issue_id);
		} )
		.subscribe( (rec:any) => {		// TODO: get url from param (future on aws?)	
			this.mag = rec;
			this.mag_image = "http://musclememory.net/magCovers/"+rec.image;
			this.mag_pdf = "http://musclememory.net/magPdfs/"+rec.image.replace('jpg','pdf');
			this._restService.hackRefresh();
		} )
	}
	
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
	
	parseAthletes( athletes:string ):boolean {
		this.len = 0;
		if( athletes ) {
			this.athletes = athletes.split('|');
			this.len = this.athletes.length;
		}
		return this.len > 0;
	}
}
