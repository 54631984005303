import {Component,Input} from '@angular/core';
import {ROUTER_DIRECTIVES} from '@angular/router';
import {TranslatePipe} from 'ng2-translate';
import {FormatMonthPipe,PrefixStrPipe} from "../../../pipes/formatting";


@Component({
	selector: '[articleItem]',
	template: `
		<a *ngIf="item.article_id" [routerLink]="['/articles', item.article_id]">{{item.article_title}}</a>
		<span *ngIf="!item.article_id">{{item.article_title}}</span>
		, <i>{{item.mag_name}}</i>
		, {{item.month | formatMonth}} {{item.year}}
		{{item.page_num == 0 ? '' : item.page_num |	prefixStr: ", Page "}}
	`,
	directives: [ROUTER_DIRECTIVES],
	pipes: [FormatMonthPipe,PrefixStrPipe]

})
export class AthleteArticleItem {
	@Input('articleItem') item;
}



@Component({
	selector: 'athlete-articles',
	template:`
		<div *ngIf='articles && articles.length > 0'>
			<h3>{{"ATHLETE_HISTORY.ONLINE_ARTICLES" | translate : {name:displayName} }}</h3>
			<ul>
				<li *ngFor="let item of articles" [articleItem]="item"></li>
			</ul>
		</div>
	`,
	styles: [`
		ul {
	    	list-style-type: none;
			padding-left:30px;
		}
	`],
	directives: [AthleteArticleItem],
	pipes: [TranslatePipe]
})
export class AthleteArticles {
	private list:any[] = [];

	@Input('display-name') displayName:string;
	@Input() articles:any[];
}



@Component({
	selector: 'athlete-authored-articles',
	template:`
		<div *ngIf='authored && authored.length > 0'>
			<h3>{{"ATHLETE_HISTORY.AUTHORED_BY"| translate : {name:displayName} }}</h3>
			<ul>
				<li *ngFor="let item of authored" [articleItem]="item"></li>
			</ul>
		</div>
	`,
	styles: [`
		ul {
    		list-style-type: none;
			padding-left:30px;
		}
	`],
	directives: [AthleteArticleItem],
	pipes: [TranslatePipe]
})
export class AthleteAuthoredArticles {
	@Input('display-name') displayName:string;
	@Input() authored:any[];
}