import {Component,Output} from '@angular/core';
@Component( {
	selector: 'alphabet-selector',
	template: `<div>
		<select #sel (change)="selected = sel.value">
			<option *ngFor="let alpha of alphabet">{{alpha}}</option>
		</select>
	</div>`
})

export class AlphabetSelector {
	selected ='A';
	alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');

}