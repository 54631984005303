import {Component,Input} from '@angular/core';
import {ROUTER_DIRECTIVES} from '@angular/router';
import {TranslatePipe} from 'ng2-translate';
import {FormatMonthPipe,PrefixStrPipe} from "../../../pipes/formatting";


@Component({
	selector: '[magRow]',
	template: `
		<td><a [routerLink]="['/mag_issue',mag.mag_issue_id]">{{mag.mag_name}}</a></td>
		<td>{{mag.month | formatMonth}} {{mag.year}}</td>
		<td>{{formatIssue(mag)}}</td>
	`,
	styles: [`
		td { padding-left: 30px; }
	`],
	directives: [ROUTER_DIRECTIVES],
	pipes: [FormatMonthPipe]
})
export class MagRowComponent {
	@Input('magRow') mag;
  
	formatIssue(mag) {
		if( mag == null ) {
			return '';
		} else if( mag.vol == 0 ) {
			return mag.num == 0 ? '' : "Issue "+mag.num;
		} else if( mag.num == 0 ) {
			return "Vol "+mag.vol;
		} else {
			return "Vol "+mag.vol+", Num "+mag.num;
		}
	}
}


@Component({
	selector: 'athlete-mags',
	template:`
		<div *ngIf="mags && mags.length" class="athlete-mags">
			<h3>{{"ATHLETE_HISTORY.MAG_COVERS"|translate}}</h3>
			<table>
				<tr *ngFor="let mag of mags" [magRow]="mag"></tr>
			</table>
		</div>
	`,
	styles: [`
	    .athlete-mags {
		}
	`],
	directives: [ MagRowComponent ],
	pipes: [TranslatePipe]
})
export class AthleteMags {
	@Input() mags:any;
}