import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
	selector: 'athlete-alphabet-selector',
    template: `
		<div class="alphabet_control_bar">
			<div class="alphabet_centered">
				<button *ngFor="let ch of alphabet" (click)='changeAlpha(ch)' [disabled]="ch ==alpha">{{ch}}</button>
			</div>
		</div>
    `,
    styles: [`
     	    .alphabet_control_bar {
         		position: relative;
     	    	width: 100%;
     	    	padding-bottom:20px;
     	    }
     	
     	    .alphabet_centered {
     	    	position: relative;
     	    	top: 0;
     	    	bottom: 0;
     	    	left: 0;
     	    	right: 0;
     	    	margin: 0 auto;
     	    	display: table;
     	    }
	`]
})
export class AthleteAlphabetSelector {
	@Input() alpha;

	alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');

	constructor( private _router: Router ) {}

	changeAlpha( ch:string ) {
		console.log( ch );
		this._router.navigate( ['/athlete/alpha', ch] );
	}
}