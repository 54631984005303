import {Component} from '@angular/core';
import {ActivatedRoute,ROUTER_DIRECTIVES} from '@angular/router';
import {TranslatePipe} from 'ng2-translate';
import {DataFilterService} from "../../services/dataFilters";
import {ParamsService} from "../../services/params";

@Component({
    template:`
    	<h4>{{"CONTESTS"|translate}}</h4>
    	<table class='contest_decade'>
			<tr *ngFor="let decade of decades">
				<td *ngFor="let year of decade"><span *ngIf="year!=0"><a [routerLink]="['/contests/year',year]">{{year}}</a></span></td>
			</tr>
		</table>
    `,
    styles: [`
   		.contest_decade tr {
    	
	    }
		.contest_decade td {
    		padding:10px;
    	}
    `],
    directives: [ROUTER_DIRECTIVES],
    pipes: [TranslatePipe],  
})
export class ContestAllYearsComponent {
	private subscription: any;
	private decades:any[];

	constructor( private _dataFilters:DataFilterService, private _params:ParamsService ) {}

	ngOnInit() {
		this._dataFilters.setFilters( false, true );
		this.subscription = this._dataFilters.filterChange$
			.merge( this._params.paramChange$ )
			.subscribe( ()=> {
				let minYear = this._dataFilters.male ? this._params.minYearMen : this._params.minYearWomen;
				let maxYear = this._dataFilters.male ? this._params.maxYearMen : this._params.maxYearWomen;
				let firstDecade = Math.floor( minYear/10 ) * 10;
				let lastDecade = Math.floor(maxYear/10) *10;
				this.decades = [];
				for( var i=firstDecade; i<=lastDecade; i += 10 ) {
					let years:number[] = new Array(10);
					for( var j=0; j<10; j++ ) {
						let year = i+j;
						years[j] = year >= minYear && year <= maxYear ? year : 0;
					}
					this.decades.push( years );
				}
			});
	}
	
	ngOnDestroy() {
		if( this.subscription ) this.subscription.unsubscribe();
	}
	
}