import {Component,Input} from '@angular/core';
import {DataFilterService} from "../../services/dataFilters";
import {TranslatePipe} from 'ng2-translate';
	
	
@Component( {
	selector: 'data-filter-summary',
	template: `
		<div>{{"FILTERS"|translate}}
			<span *ngIf="filters.male && !filters.female">{{"WITH_MEN_CONTESTANTS"|translate}}</span>
			<span *ngIf="!filters.male && filters.female">{{"WITH_WOMEN_CONTESTANTS"|translate}}</span>
		</div>
	`,
	pipes: [TranslatePipe],
})
export class DataFilterSummary {
	@Input() showYear:boolean = false;		// not implemented yet
	constructor( private filters:DataFilterService ) {}
}

