import {Component,Input} from '@angular/core';
import {ROUTER_DIRECTIVES} from '@angular/router';
import {TranslatePipe} from 'ng2-translate';

import {FormatMagRangePipe,FormatMagCountPipe} from "../../pipes/magPipes";
import {RestService} from "../../services/restCalls";

@Component({
    template:`
    <div class='mag-container'>
		<div class="mag-athletes">
			<table>
				<caption>{{"MAGAZINES.FREQUENT_COVERS"|translate}}</caption>
				<tbody>
					<tr *ngFor="let item of athletes">
						<td><a [routerLink]="['/athlete', item.athlete_id]">{{item.display_name}}</a></td>
						<td>{{item.count}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class='mag-list'>
			<ul>
				<li *ngFor="let item of magList">
					<a class='link' [routerLink]="['/magazines', item.mag_id]"><b>{{item.mag_name}}</b></a>
					{{item | formatMagRange}} {{item | formatMagCount}}</li>
			</ul>
		</div>
	</div>
    `,
    styles: [`
	    .mag-container {
	    	min-width: 400px;
	    	margin-top: 50px;
	    	margin-bottom: 50px;
	    }
	
	    .mag-athletes {
	    	margin-bottom: 100px;
	    	float: right;
	    }
	
	    .mag-athletes table {
	    	border: groove thin gray;
	    	border-collapse: collapse;
	    	border-spacing: 4px;
	    }
	
	    .mag-athletes caption {
	    	font-weight: bold;
	    	font-size: large;
	    	padding-bottom: 20px;
	    }
	
	    .mag-athletes td {
	    	padding: 1px 8px 1px 20px;
	    }
	
	    .mag-list {
	    	font-size: larger;
	    }
	
	    .mag-list li {
	    	list-style-type: none;
	    	line-height: 150%;
	    }
    `],
	directives: [ROUTER_DIRECTIVES],
    pipes: [TranslatePipe, FormatMagRangePipe, FormatMagCountPipe]
})
export class MagazinesComponent {
	private subscription:any;
	private magList:any[];
	private athletes:any[];
	
	constructor( private _restService:RestService ) {}
	
	ngOnInit() {
		this.subscription = this._restService.getMagSummary()
			.subscribe( (data) => { 
				this.athletes = data.athletes;
				this.magList = data.magList;
				this._restService.hackRefresh();
			} );
	}
	
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
