import {Component,Input,Output} from '@angular/core';
import {ParamsService} from "../../services/params";
import {DataFilterService} from "../../services/dataFilters";

@Component( {
	selector: 'year-selector',
	template: `<div>
		<select #sel (change)="selected = sel.value">
			<option *ngFor="let year of years">{{year}}</option>
		</select>
	</div>`
})
//			<option *bnLoop="#year from paramsService.minYear to paramsService.maxYear">{{year}}</option>

export class YearSelector {
/*	@Output()*/ selected = "0";
	years:any[] = [];
	subscription: any;
	
	constructor( private _paramsService:ParamsService, private _dataFilters:DataFilterService ) {}

	ngOnInit() {
		this.setYearRange();
		this.subscription = this._paramsService.paramChange$.subscribe( () => this.setYearRange() );
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
	
	setYearRange() {
		if( this._paramsService ) {
			let maxYear:number = this._paramsService.maxYearMen;
			let minYear:number = this._paramsService.minYearWomen;
			if( this._dataFilters.male ) minYear = this._paramsService.minYearMen;
			this.years = ['All'];
			for( var i=maxYear; i>=minYear; i-- ) {
				this.years.push(""+i);
			}
		}
	}
}