import {Component, Input} from '@angular/core';
import {TranslatePipe} from 'ng2-translate';
	
@Component({
	selector: 'expandable-div',
    templateUrl: 'app/views/widgets/expandableDiv.html',
    pipes: [TranslatePipe],
})
export class ExpandableDiv {
	@Input() title : string;
	@Input() titleValues = {};
	@Input() showTriangle : boolean = true;
	
	isActive : boolean = true;
}