import {Component,EventEmitter,Input, Output} from '@angular/core';
import {CORE_DIRECTIVES} from "@angular/common";
import {AbstractValueAccessor, MakeProvider} from "../../common/abstractValueAccessor";

import {ParamsService} from "../../services/params";
	
@Component( {
	selector: 'country-selector',
	template: `
		<select #sel [(ngModel)]="value" (change)="change.emit(sel.value)">
			<option *ngFor="let item of paramsService.countries" [ngValue]="item.country_code">{{item.country_name}}</option>
		</select>
	`,
	directives: [CORE_DIRECTIVES],
	providers: [MakeProvider(CountrySelector)]
})
export class CountrySelector extends AbstractValueAccessor {
	@Output() change = new EventEmitter<string>();	// this part shows value before change???
	
	constructor( public paramsService:ParamsService ) {
		super();
	}
}