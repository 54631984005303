import {Component,Input} from '@angular/core';
import {ActivatedRoute,ROUTER_DIRECTIVES} from '@angular/router';
import {TranslatePipe} from 'ng2-translate';

import {FormatMagVolNumPipe,FormatMonthYearPipe} from "../../pipes/magPipes";
import {RestService} from "../../services/restCalls";

@Component({
    template:`
		<div class='magazine-container-list'>
			<h3>{{magazine_name}}</h3>
			<p>{{"MAGAZINES.OWN"|translate}}</p>
			<table>
				<tr>
					<th style="width: 140px">{{"MAGAZINES.DATE"|translate}}</th>
					<th style="width: 110px">{{"MAGAZINES.ISSUE"|translate}}</th>
					<th>{{"MAGAZINES.COVER"|translate}}</th>
					<th>{{"MAGAZINES.COVER_MODELS"|translate}}</th>
				</tr>
				<tr *ngFor="let mag of list"
					[ngClass]="{ own_one:mag.own == 1, own_more:mag.own > 1, own_none:mag.own < 1 }">
					<td>{{mag | formatMonthYear}}</td>
					<td>{{mag | formatMagVolNum}}</td>
					<td><a [routerLink]="['/mag/issue', mag.mag_issue_id]" [hidden]="mag.code == null">scan</a></td>
					<td *ngIf="parseAthletes(mag.athletes)">
						<template ngFor let-athlete [ngForOf]="athletes" let-i="index">
							<a [routerLink]="['/athlete',athlete]">{{athlete}}{{i+1 != athletes.length ? ', ' : ''}}</a>
						</template>
					</td>
				</tr>
			</table>
		</div>
    `,
    styles: [`
		.magazine-container-list {
    		width:90%;
    		max-width:1000px;
    		margin: 0 auto;
    	}
    	.magazine-container-list tr {
    		width: 100%;
    	}
    	.magazine-container-list th {
    		text-align: left;
    		padding: 0 30px 0 4px;
    	}

    	.magazine-container-list td {
    		padding: 0 30px 0 4px;
    	}

    	.own_none {
    		
    	}

    	.own_one {
    		background-color: #ccddcc;
    	}

    	.own_more {
    		background-color: #ccccdd;
    	}
    `],
	directives: [ROUTER_DIRECTIVES],
    pipes: [TranslatePipe, FormatMonthYearPipe, FormatMagVolNumPipe]
})
export class MagListComponent {
	private subscription:any;
	private list:any[];
	private athletes:string[];
	
	constructor( 
		private _route:ActivatedRoute, 
		private _restService:RestService
	) {}
	
	ngOnInit() {
		this.subscription = this._route.params
		.map( (params) => {
			return +params['mag_id'];
		} )
		.flatMap( (mag_id, i) => { 
			return this._restService.getMags(mag_id);
		} )
		.subscribe( (list:any[]) => {
			this.list = list;
			this._restService.hackRefresh();
		} )
	}
	
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
	
	parseAthletes( athletes:string ):boolean {
		if( athletes ) {
			this.athletes = athletes.split('|');
			if( this.athletes.length > 0 ) return true;
		}
		return false;
	}
}
