import {Component} from '@angular/core';
import {TranslatePipe} from 'ng2-translate';
import {ActivatedRoute, ROUTER_DIRECTIVES} from '@angular/router';
import {RestService} from "../../services/restCalls";
import {ContestTitleComponent} from './contestTitle';
import {ContestResultGenderComponent} from './contestResultGender';
import {RelatedArticleList} from './relatedArticles';

@Component({
    templateUrl: 'app/views/contests/contest_results.html',
    directives: [ROUTER_DIRECTIVES, ContestTitleComponent, ContestResultGenderComponent, RelatedArticleList],
    pipes: [TranslatePipe],
    
})
export class ContestResultsComponent {
	private subscription:any;
	results:any = null;
	hasMale = false;
	hasFemale = false;

	constructor( private _route:ActivatedRoute, private _restService:RestService ) {}

	ngOnInit() {
		let event_id;
		this.subscription = this._route.params
		.map( (params) => { 
			event_id = +params['event_id'] 
		} )
		.merge(
			this._restService.getContestResults(event_id)
		)
		.subscribe( (data) => this.storeData(data) );
	}
	
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
	
	storeData( data:any ) {
		this.hasMale = false;
		this.hasFemale = false;
		for( var i=0; i<data.placings.length; i++ ) {
			if( data.placings[i].gender == 'male' ) this.hasMale = true;
			if( data.placings[i].gender == 'female' ) this.hasFemale = true;
			if( this.hasMale && this.hasFemale ) break;
		}
		this.results = data;
		this._restService.hackRefresh();
	}
}