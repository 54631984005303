// temp until ng2-translate ready for RC.0
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'translate'})
export class TranslatePipe implements PipeTransform {
	transform(value:string) : string {
		return value;
	}
}

export declare class TranslateService {

}

export declare const TRANSLATE_PROVIDERS: any;
declare var _default: {
    pipes: typeof TranslatePipe[];
    providers: typeof TranslateService[];
};
export default _default;
