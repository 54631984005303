import {Injectable,Inject} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/share';
import {Observer} from 'rxjs/Observer';

@Injectable()
export class UserSettingsService {
	private _showLocalized:boolean = false;
	private _showFirstLast:boolean = true;

	filterChange$: Observable<any>;
	private _filterObserver: Observer<any>;
	
	constructor( ) {
		this.filterChange$ = new Observable( (observer:any) => this._filterObserver = observer ).share();
		// fetch from local storage
	}
	
	store() {
		// store in local storage or cookie
	}

	get showLocalized():boolean {
		return this._showLocalized;
	}
	
	set showLocalized( val:boolean ) {
		this._showLocalized = val;
		this.store();
	}

	get showFirstLast():boolean {
		return this._showLocalized;
	}
	
	set showFirstLast( val:boolean ) {
		this._showLocalized = val;
		this.store();
	}
}