import {Component,ViewChild} from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import {RestService} from "../../services/restCalls";
import {DataFilterService} from "../../services/dataFilters";
import {AthleteAlphabetSelector} from "./athleteAlphabetSelector";
import {AthleteSearchList} from "./athleteSearchList";

@Component({
    template: `
    <div class='athlete-alpha-list'>
		<athlete-alphabet-selector [alpha]="alpha" [(searchStream)]="searchStream"></athlete-alphabet-selector>
		<athlete-search-list #searchList [list]="filteredList"></athlete-search-list>
	</div>
    `,
    directives: [AthleteAlphabetSelector,AthleteSearchList],
   
})
export class AthleteAlphaListComponent {
	@ViewChild(AthleteSearchList) searchList:AthleteSearchList;
	
	subscription: any;
	list:any[] = [];

	constructor( 
			private _router: Router, 
			private _route: ActivatedRoute, 
			private _restService:RestService, 
			private _dataFilters:DataFilterService
	) {}

	ngOnInit() {		//probably a good place to merge streams fetchData and changeFilter
		this._dataFilters.setFilters( true, true );

		let dataStream = this._route.params.map( (params) => { 
			return params['alpha'];
		} )
		.flatMap( (alpha, i) => {
			return this._restService.getAthletesByAlpha(alpha);
		} )
		.map( (list:any[]) => { 
			this.list = list; 
		} );

		this.subscription = this._dataFilters.filterChange$
			.merge(dataStream)
			.subscribe( () => { 
				this.searchList.filterAthletes(this.list,true);
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.list = [];
	}
}