import {Component} from '@angular/core';
import {TranslatePipe} from 'ng2-translate';
	
@Component({
    templateUrl: 'app/views/info/Disclaimer.html',
    styles: [`
		.disclaimer {
    		top: 0;
    		bottom: 0;
    		left: 0;
    		right: 0;
    		width: 700px;
    		margin: 50px auto;
    	}
	`],
    pipes: [TranslatePipe]
})
export class DisclaimerComponent {}
