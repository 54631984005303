import {Component} from '@angular/core';
import {ActivatedRoute,ROUTER_DIRECTIVES} from '@angular/router';
import {TranslatePipe} from 'ng2-translate';
import {RestService} from "../../services/restCalls";
import {DataFilterService} from "../../services/dataFilters";

@Component({
    templateUrl: 'app/views/contests/contest_years.html',
    directives: [ROUTER_DIRECTIVES],
    pipes: [TranslatePipe],
    
})
export class ContestByYearsComponent {
	private subscription: any;
	private contest_id:number;
	private list:any[] = [];
	decades:ContestDecades;
	contest_name:string;
	
	constructor( private _route:ActivatedRoute, private _restService:RestService, private _dataFilters:DataFilterService ) {}

	ngOnInit() {
		this._dataFilters.setFilters( false, true );
		
		let dataStream = this._route.params.map( (params) => { 
			console.log( 'contestByYear', params);
			return params['contest_id'];
		} )
		.flatMap( (contest_id, i) => {
			return this._restService.getContestYears(+contest_id);
		} )
		.map( (list:any[]) => { 
			this.list = list; 
		} );
		
		this.subscription = this._dataFilters.filterChange$
			.merge(dataStream)
			.subscribe( () => {
				this.filterContests();
		       }
		   );
	}

	ngOnDestroy() {
		if( this.subscription ) this.subscription.unsubscribe();
	}
	
	storeData( data:any ) {
		this.contest_name = data.contest_name;
		this.list = data.list;
	}
	
	filterContests() {
		let male = this._dataFilters.male;
		let female = this._dataFilters.female;
		this.decades = new ContestDecades();
		for( var i=0; i<this.list.length; i++ ) {
			var item = this.list[i];
			if( (male && item.male) || (female && item.female) ) {
				this.decades.addItem( item );
			}
		}
		this._restService.hackRefresh();
	}
}

class ContestDecades {
	decade:ContestDecade[];
	
	constructor() {
		this.decade = [];
	}
	
	addItem( item:any ) {
		let i = this.findDecade( Math.floor(item.year/10)*10 );
		this.decade[i].addItem( item );
	}
	
	private findDecade( year:number ):number {
		let i = 0;
		for( i=0; i<this.decade.length; i++ ) {
			if( this.decade[i].year == year ) return i;
		}
		this.decade.push( new ContestDecade(year) );
		return i;
	}
}

class ContestDecade {
	year:number;
	item:any[];
	constructor(year:number) {
		this.year = year;
		this.item = new Array(10);
	}
	addItem( item:any ) {
		let i = item.year - this.year;
		this.item[i] = item;
	}
}