import { bootstrap } from '@angular/platform-browser-dynamic';

//Extend Observable throughout the app
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/startWith';


import { HTTP_PROVIDERS } from '@angular/http';
import { TRANSLATE_PROVIDERS } from 'ng2-translate';
//import { enableProdMode } from '@angular/core';
//import {LocalStorageService, LocalStorageSubscriber} from 'angular2-localstorage/LocalStorageEmitter';

import {MYCONFIG_PROVIDER} from './config';
import {AppComponent} from './components/app';
import {APP_ROUTER_PROVIDERS} from './components/routes';


//import {disableDeprecatedForms, provideForms} from '@angular/forms';

//enableProdMode();

//var appPromise = 
bootstrap(AppComponent, [
//		disableDeprecatedForms(),	// not needed after rc2
//		provideForms(),				// not needed after rc2
		HTTP_PROVIDERS, 
		APP_ROUTER_PROVIDERS, 
		TRANSLATE_PROVIDERS, 
		MYCONFIG_PROVIDER
	] );
//LocalStorageSubscriber(appPromise);