import {Component,ElementRef} from '@angular/core';
import {OrgSelector} from "./orgSelector";
//import {Ng2SliderComponent} from "ng2-slider-component/ng2-slider.component";
import {TranslatePipe} from 'ng2-translate';
	
import {ParamsService} from "../../services/params";
import {DataFilterService} from "../../services/dataFilters";

@Component({
	selector: 'musmem-filter',
    template: `
<div>
	<div *ngIf="filters.showYears || filters.showGenders || filters.showOrgs">
	
		<button (click)="toggleSelect()">{{'FILTERS'|translate}}</button>
	
		<div class="rfd_dialog" *ngIf="isPopupVisible">
			<table>
				<thead>
					<tr>
						<th colspan="" >{{'FILTER_BY'|translate}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngIf="filters.showGenders">
						<td>Gender</td>
						<td><input type="checkbox" #male [(ngModel)]="filters.male">{{'MEN'|translate}}
						&nbsp;&nbsp;
						<input type="checkbox" #female [(ngModel)]="filters.female">{{'WOMEN'|translate}}</td>
					</tr>
					<tr *ngIf="filters.showYears" style="vertical-align:middle;">
						<td>Years</td>
						<td style="width:300px; padding-top:16px">
							<ng2-slider 
							    min="6"
							    max="23"
							    startValue="9"
							    endValue="21"
							    stepValue="2"
							    [normalHandlerStyle]="{ 'background-color': 'green'}"
							    [slidingHandlerStyle]="{
							          'border-radius': '9px',
							          'background-color': 'red'
							    }">
							</ng2-slider>
						</td>
					</tr>
					<tr *ngIf='filters.showOrgs'>
						<td>{{"ORG"|translate}}</td>
						<td><org-selector #org (onChange)="onOrgChange($event)" [filter]="filters.orgFilters"></org-selector></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
`,
    directives: [OrgSelector /*, Ng2SliderComponent*/],
    pipes: [TranslatePipe],
    styles: [`
        .rfd_dialog {
	    	position: fixed;
	    	right: 60px;
	    	top: 60px;
	    	padding: 10px;
			border: outset thick #999;
		    box-shadow: 10px 10px 5px #aaa;
	    	background-color: white;
	    	z-index: 5002;
        }
        .rfd_outer_list {
        	list-style-type: none;
        }

        .rfd_list {
        	display: inline-flex;
        	padding: 0;
        }

        .rfd_list li {
        	padding: 0 10px 0 0;
        	list-style-type: none;
        }

        .rfd_dialog slider {
        	display: block !important;
        }
    `],
	host: { '(document:click)': 'onClick($event)' },
})
export class DataFilterDialog {
	isPopupVisible: boolean = false

	constructor( 
			public paramsService:ParamsService, 
			public filters:DataFilterService,
			private _eref: ElementRef
	) {}

	toggleSelect() {
		this.isPopupVisible = !this.isPopupVisible;
	}
	
	onOrgChange( org_id:number ) {
		this.filters.org = org_id;
	}
	
	onClick(event) {
		if ( this.isPopupVisible && !this._eref.nativeElement.contains(event.target)) {
			this.isPopupVisible = false;
		}
	}
}
