import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'objectValues'})
export class ObjectValuesPipe implements PipeTransform {
    transform( value:any, args?:any[] ): Object[] {
        let keyArr:string[] = Object.keys(value);
        let dataArr:any[] = [];
		let keyName:string = args[0];

        keyArr.forEach(key => {
            value[key][keyName] = key;
            dataArr.push(value[key])
        });

        if(args[1]) {
            dataArr.sort((a: Object, b: Object): number => {
                return a[keyName] > b[keyName] ? 1 : -1;
            });
        }

        return dataArr;
    }
}