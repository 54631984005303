import {Component,Inject,Output,NgZone} from '@angular/core';
import {TranslateService} from 'ng2-translate';
//import {Cookie} from 'ng2-cookies';
import {MYCONFIG_PROVIDER} from '../../config';
	
@Component( {
	selector: 'language-selector',
	template: `<div class='language-selector'>
		<select #sel (change)="changeLanguage(sel.value)">
			<option *ngFor="let lang of languages" [value]='lang.value'>{{lang.label}}</option>
		</select>
	</div>`,
	styles: [`
	    .language-selector {
			padding-left:16px;
			padding-top:4px;
		}
	`]
})

export class LanguageSelector {
	currentLang:string = 'en';
	languages: any[] = [ {value: "en", label: "English"}];
	
	constructor( public translate: TranslateService, private _ngZone:NgZone ) {
		this.languages = MYCONFIG_PROVIDER[0].token.languages;			// there must be a better way of extracting value
	}
	
	ngOnInit() {
		this.translate.setDefaultLang('en');
		this.getLangCookie();
	}
	
	getLangCookie() {
//		this.currentLang = Cookie.getCookie('language');
		if( this.currentLang == null || this.currentLang == '' ) {
	        this.currentLang = navigator.language.split('-')[0];
	        this.setLangCookie();
	   }
		this.translate.use(this.currentLang);
	}
	
	changeLanguage(value:string) {
		this.currentLang = value;
		this.setLangCookie();
		this.translate.use(this.currentLang);
		// hack for now
		setTimeout( ()=> {this._ngZone.run( () => {} )}, 500 );
		// store in cookie
		// change translate
	}

	setLangCookie() {
//		Cookie.setCookie( 'language', this.currentLang );
	}

}