import {Pipe, PipeTransform} from '@angular/core';
import {UserSettingsService} from "../services/userSettings";

// not the best place for these....

function escapeRegExp(str:string) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}
function replaceAll(str:string, find:string, replace:string) {
  return str == null ? '' : str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

function strMatch( str:string, matchString:string ) {
	return str.toString().toLowerCase().indexOf(matchString) != -1;
}

@Pipe({name: 'escapeHtml', pure: false})
export class EscapeHtmlPipe implements PipeTransform {
   transform(value: any, args: any[] = []) {
       // TODO: Escape 'value' and return it
       return value;
   }
}

@Pipe({name: 'localizeNumber'})
export class LocalizeNumberPipe implements PipeTransform {
	transform(value:number):string {
		return value == null ? 'null' : value.toLocaleString();
	}
}

@Pipe({name: 'thousands'})
export class ThousandsPipe implements PipeTransform {
  transform(value:number) : number {
    return Math.floor(value/1000);
  }
}

@Pipe({name:'formatMonth'})
export class FormatMonthPipe implements PipeTransform {
	transform(input:number) : string {
		var month = [ 'approx', 'January', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', 'unknown'];
		return input < 0 ? '' : month[input];
	}
}

@Pipe({name:'formatDate'})
export class FormatDatePipe implements PipeTransform {
	transform(input:string) : string {
		if( input == undefined || input == null ) {
			return '';
		} else if( input.match( /-00-00/ ) != null ) {
			return input.substr(0,4);
		} else if( input.match( /-00/ ) != null ) {
			var month = new FormatMonthPipe().transform( parseInt( input.substr(5,2) ) );
			return month+' '+input.substr(0,4);
		} else {
			return input == null || input == '' ? '' : new Date(input).toDateString();
		}
	}
}

@Pipe({name:'mapYesNo'})
export class MapYesNoPipe implements PipeTransform {
	transform(input:number) : string {
		return input == 0 ? "No" : "Yes";
	}
}

@Pipe({name:'mapTrueFalse'})
export class MapTrueFalsePipe implements PipeTransform {
	transform(input:number) : string {
		return input == 0 ? "False" : "True";
	}
}

@Pipe({name:'suffixStr'})
export class SuffixStrPipe implements PipeTransform {
	transform(str:string, [suffix]) : string {
		return str == null || str.length == 0 ? "" : str+suffix;
	}
}

@Pipe({name:'prefixStr'})
export class PrefixStrPipe implements PipeTransform {
	transform(str:string, [prefix]) : string {
		return str == null || str.length == 0 ? "" : prefix+str;
	}
}

@Pipe({name:'prefixSuffixStr'})
export class PrefixSuffixStrPipe implements PipeTransform {
	transform(str:string, [prefix, suffix]) : string {
		return str == null || str.length == 0 ? "" : prefix+str+suffix;
	}
}

@Pipe({name:'formatContestName'})
export class FormatContestNamePipe implements PipeTransform {
	transform( item:any, [no_org] ) : string {
		if( item == null ) return '';
		var name = item.contest_name;
		if( item.org_abbrev && !no_org && item.org_abbrev.toLowerCase() != "musclemania") name += " - " + item.org_abbrev;
		return replaceAll( name, "&#039;", "'" );
	}
}

@Pipe({name:'decadesWithContests'})
export class DecadesWithContestsPipe implements PipeTransform {
	transform( list:any[], [filters]) : any {
		var prevDecade = 0;
		var results = new Array();
		list.forEach( function( item, key ) {
			if( (item.male == 1 && filters.genders.male.value) || 
					(item.female == 1 && filters.genders.female.value ) ) {
				var decade = Math.floor( item.year/10 )*10;
				if( prevDecade != decade ) {
					prevDecade = decade;
					results.push( decade );
				}
			}
		} );
		return results;
	}
}

@Pipe({name:'yearsWithContest'})
export class YearsWithContestPipe implements PipeTransform {        // needs work
	transform( decade:number, [list, filters]) : any {
        var results = new Array(10);
		for( var i=0; i<10; i++ ) {
            results[i] = { contest_event_id:0, year:decade+i };
        }
        results.forEach( function(item) {
			if( item.year >= decade && item.year < decade+10 ) {
				if( (item.male == 1 && filters.genders.male.value) || 
						(item.female == 1 && filters.genders.female.value ) ) {
					results[item.year-decade].contest_event_id = item.contest_event_id;
				}
			}
		});
		return results;
	};
}

@Pipe( {name:'isEmpty'} )
export class IsEmptyPipe implements PipeTransform {
	transform( obj:any, [trueResult=true, falseResult = false] ) {
		if( obj != null ) {
			if( obj instanceof Array || obj instanceof String ) {
				return obj.length == 0 ? trueResult : falseResult;
			}
			for( var bar in obj) {
				if( bar != null && obj.hasOwnProperty(bar)) {
					return falseResult;
				}
			}
		}
		return trueResult;
	}
}

@Pipe( {name:'placed'} )
export class PlacedPipe implements PipeTransform {
	transform( val:number ):string {
		switch( val ) {
			case 0 : return 'Overall';
			case 95 : return 'Pending';
			case 96 : return 'Unk';
			case 97 : return 'Wdr';
			case 98 : return '-';
			case 99 : return 'DQ';
			default: return val.toString();
		}
	}
}

@Pipe( {name:'nth'} )
export class NthPipe implements PipeTransform {
	transform( val:number ):string {
		switch( val ) {
			case 0 : return 'Overall';
			case 95 : return 'Pending';
			case 96 : return 'Unknown Placing';
			case 97 : return 'Withdrawn';
			case 98 : return 'Did not place';
			case 99 : return 'Disqualified';
			case 11 : return '11th';
			case 12 : return '12th';
			default:
				switch( val%10 ) {
				case 1 : return val+'st';
				case 2 : return val+'nd';
				default: return val+'th';
			}
		}
	}
}

@Pipe( {name:'formatAthleteName'} )
export class FormatAthleteNamePipe implements PipeTransform {
	constructor ( private _settings:UserSettingsService ) {}
	transform( obj:any ):string {
		var name = this._settings && this._settings.showFirstLast ? obj.display_name : obj.complete_name;
		if( this._settings && this._settings.showLocalized && obj.hasOwnProperty( 'localized_name' ) ) {
			name += '&nbsp;&nbsp;&nbsp;&nbsp;' + obj.localized_name;
		}
		return name;
	}
}
