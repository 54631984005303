import {Component,Input} from '@angular/core';
import {ROUTER_DIRECTIVES} from '@angular/router';

import {TranslatePipe} from 'ng2-translate';
import {FormatDatePipe,PrefixStrPipe,FormatMonthPipe} from "../../pipes/formatting";
import {RestService} from "../../services/restCalls";

@Component({
	selector: 'related-article-list',
    templateUrl: 'app/views/media/related_articles.html',
    directives: [ROUTER_DIRECTIVES],
    pipes: [TranslatePipe,FormatMonthPipe,PrefixStrPipe],
    
})
export class RelatedArticleList {
	@Input() articles: any[] = [];
	
	showArticle( article_id:number ) {
		console.log("show article ",article_id);
	}
}