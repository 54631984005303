import {Component} from '@angular/core';
import {ActivatedRoute,ROUTER_DIRECTIVES} from '@angular/router';
import {Observable} from 'rxjs/Rx';
import {TranslatePipe} from 'ng2-translate';

import {RestService} from "../../services/restCalls";
import {DataFilterService} from "../../services/dataFilters";
import {FormatContestNamePipe} from "../../pipes/formatting";
import {OrgTitle} from "./orgTitle";

@Component({
    templateUrl: 'app/views/contests/contests_by_org.html',
    directives: [ROUTER_DIRECTIVES, OrgTitle],
    pipes: [TranslatePipe, FormatContestNamePipe],
    
})
export class ContestsByOrgComponent {
	private subscription: any;
	private list:any[] = [];
	filteredList:any[] = [];
	org_id:number;
	no_org:boolean = true;

	constructor( private _route:ActivatedRoute,
				 private _restService:RestService, 
				 private _dataFilters:DataFilterService ) {}

	ngOnInit() {
		this._dataFilters.setFilters( false, true );
		
		let dataStream = this._route.params.map( (params) => { 
			this.org_id = +params["org_id"];
			return this.org_id;
		})
		.flatMap( (org_id, i) => {
			return this._restService.getContestsByOrg(org_id);
		} )
		.map( (list:any[]) => { 
			this.list = list;
		} );
			
		this.subscription = this._dataFilters.filterChange$
			.merge(dataStream)
			.subscribe( () => this.filterContests() );
	}

	ngOnDestroy() {
		if( this.subscription ) this.subscription.unsubscribe();
	}
	
	filterContests() {
		console.log( 'filter contest ', this.list );
		if( this.list ) {
			let male = this._dataFilters.male;
			let female = this._dataFilters.female;
			this.filteredList = [];
			for( var i=0; i<this.list.length; i++ ) {
				var item = this.list[i];
				if( (male && item.male) || (female && item.female) ) {
					this.filteredList.push( item );
				}
			}
			this._restService.hackRefresh();
		}
	}
}