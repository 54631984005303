import {Injectable,Inject} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/share';
import {Observer} from 'rxjs/Observer';

@Injectable()
export class DataFilterService {

	showYears:boolean = false;
	showGenders:boolean = false;
	showOrgs:boolean = false;
	
	minYear:number = 1930;
	maxYear:number = new Date().getFullYear();
	
	setFilters( years:boolean=false, genders:boolean=false, orgs:boolean=false) {
		this.showYears = years;
		this.showGenders = genders;
		this.showOrgs = orgs;
		this._orgFilters = [];
		// do I want to reset each new page?
		this._org = -1;
	}

	private _male:boolean = true;
	private _female:boolean = true;
	private _from:number = 1930;
	private _to:number = this.maxYear;
	private _org:number = -1;
	private _orgFilters:string[] = [];

	filterChange$: Observable<any>;
	private _filterObserver: Observer<any>;
	
	constructor( ) {
		this.filterChange$ = new Observable( (observer:any) => this._filterObserver = observer ).share();
		// fetch from local storage
	}
	
	store( flag:boolean ) {
    	console.log( 'after', this._male, this._female );
		if( flag && this._filterObserver ) {
			this._filterObserver.next('');
		}
		// store in localstorage
	}
	
    get male():boolean { 
    	return this._male;
    }
    
	set male( val:boolean ) {
    	console.log( 'f', val, this._male, this._female );
		this._male = val;
    	if( !this._male ) this._female = true;
		this.store(true);
	}
	
	get female():boolean { 
    	return this._female;
    }
    
    set female( val:boolean ) {
    	console.log( 'f', val, this._male, this._female );
    	this._female = val;
    	if( !this._female ) this._male = true;
    	this.store(true);
    }
    
    get from():number { 
    	return this._from;
    }
    
    set from( val:number ) {
    	this._from = Math.min( Math.max( this.minYear, val ), this.maxYear );
    	this.store(true);
    }
    get to():number { 
    	return this._to;
    }
    
    set to( val:number ) {
    	this._to = Math.max( Math.min( this.maxYear, val), this.minYear );
    	this.store(true);
    }
    
    get org():number { 
    	return this._org;
    }
    
	set org( val:number ) {
		this._org = val;
		this.store(true);
	}
	
	get searchParams():string {
		return 'xxx yyy zzz';
	}
	
	get orgFilters():string[] {
		return this._orgFilters;
	}
	
	setOrgFilters( list:any[] ) {
		let orgs = [];
		for( var i=0; i<list.length; i++ ) {
			orgs[list[i].org_id] = 1;
		}
		this._orgFilters = ["-1", ...Object.keys( orgs ) ];
	}
}