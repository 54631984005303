import {Injectable} from '@angular/core';

@Injectable()
export class LoadingIconService {
	visible:number = 0;
	private _timer:any;

	constructor() { console.log( 'construct loadign icon service')}

	show() {
		this.startTimer();
		this.visible++;
	}

	hide() {
		this.visible--;
		if( this.visible < 1 ) {
			this.visible = 0;
			this.stopTimer();
		}
	}

	private startTimer() {
		this.stopTimer();
		this._timer = setTimeout( ()=> { 
				alert('loading may have failed');
				this.hide();
			}, 10000 );
	}

	private stopTimer() {
		if( this._timer ) {
			clearTimeout(this._timer);
			this._timer = null;
		}
	}
}
