import {Component, Input} from '@angular/core';

@Component({
	selector: 'social-media',
    template: `
	    <ul class='socialMedia'>
			<li *ngIf="repetrope_url">
				<a target="_blank" [href]="repetrope_url"><img src="/imgs/repetrope.jpg" /></a>
			</li>
			<li *ngFor="let item of items">
				<a [href]="item.url" target="_blank"><img [src]="item.icon"/></a>
			</li>
		</ul>
    `,
    styles: [`
	    .socialMedia {
	    	list-style-type: none;
	    	position: absolute;
	    	top: 60px;
	    	right: 50px;
	    	width: 124px;
	    }
	
	    .socialMedia li {
	    	
	    }
	
	    .socialMedia img {
	    	padding-bottom: 10px;
	    	float: right;
	    	opacity: 0.9;
	    }
	
	    .socialMedia img:hover {
	    	opacity: 1;
	    }    
    `],
    directives: []
})
export class SocialMedia {
	private repetrope_url:string = null;
	private items:any[];

	private tags = [
		{ tag:'facebook', icon:'Facebook', url_template:'https://www.facebook.com/%%', url:''},
		{ tag:'linkedin', icon:'LinkedIn', url_template:'https://www.linkedin.com/in/%%', url:'' },
		{ tag:'twitter', icon:'Twitter', url_template:'https://twitter.com/%%/', url:'' },
	];


	@Input() set user( user:any ) {
		this.repetrope_url = null;
		this.items = [];
		if( user != undefined ) {
			if( user.repetrope_id ) {
				this.repetrope_url = "http://www.repetrope.com/men/competitors/detail.asp?CompetitorID="+user.repetrope_id;
			}
			for( let item of this.tags ) {
				if( user.hasOwnProperty( item.tag ) && user[item.tag].length > 0 ) {
					this.items.push( { 
						url: item.url_template.replace( '%%', user[item.tag] ), 
						icon: "/imgs/social/"+item.icon+".png"
					} );
				}
			}
		}
	}
}