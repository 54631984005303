import {Component,Input} from '@angular/core';
import {ActivatedRoute,ROUTER_DIRECTIVES} from '@angular/router';

import {TranslatePipe} from 'ng2-translate';
import {PrefixStrPipe,FormatDatePipe} from "../../../pipes/formatting";

import {EmbeddedHtml} from "../../widgets/embeddedHtml";
import {LabeledValue} from "../../widgets/labeledValue";
import {SocialMediaIcons} from '../../widgets/socialMediaIcons';
import {SocialMedia} from '../../widgets/socialMedia';


@Component({
	selector: 'athlete-info',
	template:`
	<div class='athlete-info' *ngIf="history">
		<img *ngIf="history.images && history.images.length != 0"
			[src]="history.images[0].image" />
		<div>
			<social-media-icons [item]='history.athlete'></social-media-icons>
			<labeled-value [label]="notAthlete(history.athlete)" value=' '></labeled-value>
			<labeled-value [value]="history.athlete.ascii_name_fl"></labeled-value>
			<labeled-value [value]="history.athlete.localized_name"></labeled-value>
			<embedded-html [html]="history.athlete.comment"></embedded-html>
			<labeled-value 
				[value]="(history.athlete.born | formatDate) + (history.athlete.birthplace | prefixStr : ', ')"
				label="ATHLETE_HISTORY.BORN"></labeled-value>
			<labeled-value
				[value]="(history.athlete.died | formatDate)+ (history.athlete.death_location | prefixStr: ', ')"
				label="ATHLETE_HISTORY.DIED"></labeled-value>
			<labeled-value [value]="history.athlete.height" label='ATHLETE_HISTORY.HEIGHT'></labeled-value>
			<labeled-value [value]="history.athlete.weight" label='ATHLETE_HISTORY.WEIGHT'></labeled-value>
			<embedded-link [link]="history.athlete.url"></embedded-link>
			<button *ngIf="history.images.length > 1" class="btn"
				(click)='showImageViewer(history.images)'>{{'ATHLETE_HISTORY.MORE_PHOTOS'|translate}}</button>
		</div>
		<social-media user="history.athlete"></social-media>
	</div>
	`,
	styles: [`
	    .athlete-info img {
	    	float:left;
	    	margin-right:40px;
	    }
		.athlete-info > div {
			float:left;
		}
		.athlete-info:after {
		    content: '';
	 	   display: block;
	 	   clear: both;
		}
	`],
    directives: [
                 ROUTER_DIRECTIVES, 
             	SocialMediaIcons, 
             	LabeledValue, 
             	SocialMedia, 
             	EmbeddedHtml,
             ],
	pipes: [TranslatePipe,FormatDatePipe,PrefixStrPipe]
})
export class AthleteInfo {
	@Input() history:any;
	
	notAthlete( athlete:any ) {
		if( athlete == undefined || athlete.inactive == 0 ) return '';
		var c = athlete.complete_name.charAt(0);
		return c.toUpperCase() == c ? "ATHLETE_HISTORY.NOT_BODYBUILDER" : "ATHLETE_HISTORY.MAG_COVER_DESC";
	}
}