import {Component,Input} from '@angular/core';
import {ActivatedRoute,ROUTER_DIRECTIVES} from '@angular/router';
import {TranslatePipe} from 'ng2-translate';

import {SuffixStrPipe,NthPipe} from "../../../pipes/formatting";

@Component({
	selector: 'athlete-contest-history',
	template:`
		<div *ngIf="contests && contests.length > 0" class='athlete-contest-history'>
			<h3>{{"ATHLETE_HISTORY.CONTEST_HISTORY"|translate}}</h3>
			<template ngFor let-year [ngForOf]="contests">
				<div class='athlete-contest-history-year'>
					<h4>{{year.year}}</h4>
					<template ngFor let-item [ngForOf]="year.list">
						<div class='athlete-contest-history-events'>
							<a [routerLink]="['/contest',item.contest_event_id]">{{item.full_contest_name}}</a>
								, {{item.div_label | suffixStr:", " }} {{item.placing |nth}}
						</div>
					</template>
				</div>
			</template>
		</div>
	`,
	styles: [`
		.athlete-contest-history-year {
			padding-left:30px;
		}
		.athlete-contest-history-events {
			padding-left:30px;
		}
	`],
	directives: [ ROUTER_DIRECTIVES ],
	pipes: [TranslatePipe,SuffixStrPipe,NthPipe]
})
export class AthleteContestHistory {
	@Input() contests;
}