import {Component} from '@angular/core';
import {TranslateService, TranslatePipe} from 'ng2-translate';

@Component({
    templateUrl: 'app/views/media/Books.html',
    styles: [` 
        .books {
    	
	    }
    	.books h2 {
    		width:700px;
	    	margin: auto;
    	}
	    .books-container {
	   		width: 100%;
			height: 100%;
			padding-bottom:100px;
			overflow: scroll;
	    }
	    .books-content {
			width: 700px;
			margin: 0 auto;
	    }
	    .books-content dd {
	    	margin-left: 30px;
	    	margin-bottom: 10px;
	    }
	    .books-content dl {
	    	margin-bottom: 40px;
	    }
	`],
    pipes: [TranslatePipe]
})
export class BooksComponent {

}
