import {Component,Input} from '@angular/core';
import {ROUTER_DIRECTIVES} from '@angular/router';
import {SuffixStrPipe,NthPipe} from "../../pipes/formatting";

@Component({
    template: `
    	<div style="padding-left:30px">
    			<a [routerLink]="[ 'ContestEvent',{event_id:item.contest_event_id)}]" class="link">
    				{{item.full_contest_name}}</a>, {{item.div_label | suffixStr : ", " }} 
    					{{item.placing |nth}}
    	</div>
    `,
    directives: [ROUTER_DIRECTIVES],
    pipes: [SuffixStrPipe,NthPipe],
})
export class AthleteContestPlacing {
	@Input() item:any;
}