import {Component} from '@angular/core';
import {ActivatedRoute,ROUTER_DIRECTIVES} from '@angular/router';
import {TranslatePipe} from 'ng2-translate';
import {FormatMonthPipe,PrefixStrPipe} from "../../pipes/formatting";
import {RestService} from "../../services/restCalls";

//			<h3>{{article.title}}</h3>
//			<h4>{{article.mag_name}}, {{article.month | formatMonth}} {{article.year}}</h4>

@Component({
    template: `
    	<div class='article' *ngIf="article">
			<div [innerHtml]="article.content"></div>
		</div>
    `,
    styles: [`
		.article {
    		width:90%;
    		max-width:1000px;
    		margin: 0 auto;
	    }
    
    .article-container {
    	position: fixed;
    	top: 50px;
    	bottom: 40px;
    	left: 0;
    	right: 0;
    	overflow: hidden;
    }

    .article-viewer {
    	position: relative;
    	height: 100%;
    	overflow: auto;
    	padding: 0 10px;
    }

    article-container {
    	display: block;
    	max-width: 800px;
    	margin: 0 auto;
    }

    article-title {
    	display: block;
    	font-size: 1.17em;
    	margin-before: 1em;
    	margin-after: 1em;
    	margin-start: 0;
    	margin-end: 0;
    	font-weight: bold;
    }

    article-date {
    	display: inline-block;
    	float: right;
    	font-size: 1.17em;
    	margin-before: 1em;
    	margin-after: 1em;
    	margin-start: 0;
    	margin-end: 0;
    	color: #990000;
    }

    article-author {
    	display: inline-block;
    }

    article-content {
    	display: block;
    }
    `],
    pipes: [TranslatePipe,FormatMonthPipe,PrefixStrPipe]
})
export class ArticleComponent {
	private subscription:any;
	private article:string;

	constructor( 
		private _route:ActivatedRoute, 
		private _restService:RestService
	) {}
	
	ngOnInit() { 
		this.subscription = this._route.params
			.map( (params) => {
				return +params['article_id'];
			} )
			.flatMap( (article_id, i) => { 
				return this._restService.getArticle(article_id);
			} )
			.subscribe( (rec:any) => {
				this.article = rec;
				console.log( rec );
				this._restService.hackRefresh();
			}
		);
	}
	
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
