import {Component,Input} from '@angular/core';

declare var unescape;

@Component({
	selector: 'embedded-html',
    template:`<div [innerHtml]="_html"></div>`,
})
export class EmbeddedHtml {
	private _html;
	@Input() set html( str:string ) {
		this._html = "<div>"+this.decodeHtmlEntity(str)+"</div>";
	}
	
	decodeHtmlEntity(str)  {		//brute force
	return str ? str.replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&amp;/g,'&').replace(/&quot;/g,"'") : '';
	};
}