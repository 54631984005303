import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RestService} from "../../services/restCalls";
import {DataFilterService} from "../../services/dataFilters";
import {Subject} from "rxjs";

import {AthleteSearchForm} from "./athleteSearchForm";
import {AthleteSearchList} from "./athleteSearchList";

@Component({
	template: `
		<athlete-search-form [data]="data" [limit]="limit" [(searchStream)]="searchStream"></athlete-search-form>
		<athlete-search-list #searchList></athlete-search-list>
	`,
	directives: [AthleteSearchForm, AthleteSearchList],
})
export class AthleteSearchComponent {
	@ViewChild(AthleteSearchList) searchList:AthleteSearchList;
	
	data = { match:'', country_code:'' };
	limit:boolean = false;
	
	private subscription: any;
	private list:any[] = [];

	searchStream:Subject<any> = new Subject<any>();

	constructor( 
			private _route:ActivatedRoute, 
			private _restService:RestService,
			private _dataFilters:DataFilterService
	) {}
	
	ngOnInit() {
		this._dataFilters.setFilters( true, true );
		let dataStream = this._route.params
			.map( (params) => {
				this.data = {
					match: params['match'],
					country_code: params['country_code']
				}
				return this.data;
			} )
			.merge( 
				this.searchStream.map( (data) => {
					this.data = data;
					console.log( '---> --->', data );
					return data; 
				})
			)
			.flatMap( (data, i) => {
				return this._restService.getAthleteSearch(data.match,data.country_code);
			} )
			.map( (list:any[]) =>  {
				this.limit = list.length > 1000;
				this.list = list;
			}
		);
		this.subscription = this._dataFilters.filterChange$
			.merge( dataStream )
			.subscribe( () => {
				this.searchList.filterAthletes(this.list);
			}
		);
	}

	ngOnDestroy() {
		if( this.subscription ) this.subscription.unsubscribe();
		this.list = [];
	}
}