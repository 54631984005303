import {Component, Input} from '@angular/core';

@Component({
	selector: 'social-media-icons',
    template:`
    <div>
	<a *ngIf="item.repetrope_id" target="_blank"
		href='http://usamuscle.com/men/athletes/detail.php?AthleteID={{item.repetrope_id}}'>
		<img border='0' src='/imgs/repetrope.jpg' alt='[Images At Repetrope]' />
	</a>
	<!-- others -->
</div>
    `
})
export class SocialMediaIcons {
	@Input() item:any;
}