import {Component,Input} from '@angular/core';
import {TranslatePipe} from 'ng2-translate';
import {ROUTER_DIRECTIVES} from '@angular/router';
import {RestService} from "../../services/restCalls";
import {PlacedPipe, PrefixSuffixStrPipe, FormatAthleteNamePipe} from "../../pipes/formatting";	

@Component({
	selector: 'contest-results-gender',
    templateUrl: 'app/views/contests/contest_results_gender.html',
    directives: [ROUTER_DIRECTIVES],
    pipes: [TranslatePipe, PlacedPipe, PrefixSuffixStrPipe, FormatAthleteNamePipe ],
    
})
export class ContestResultGenderComponent {
	@Input() gender:string;
	@Input() placings: any[] = [];
}