import {Component,Input} from '@angular/core';
import {ParamsService} from "../../services/params";
import {TranslatePipe} from 'ng2-translate';

@Component({
	selector: 'org-title',
	template: `<h3>{{paramsService.org_abbrev(org_id)}} <span>{{"CONTESTS"|translate}}</span> <small results-filter-summary></small></h3>`,
	pipes: [TranslatePipe]
})
export class OrgTitle {
	@Input() org_id:any;
	
	constructor( public paramsService:ParamsService ) {}
}