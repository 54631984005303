import {Component} from '@angular/core';

@Component( {
	selector: 'copyright',
	template: "<div class='copyright'>&copy; MuscleMemory, since 1997</div>",
	styles: [`
		.copyright { 
			position: fixed;
			bottom: 10px;
			width: 100%;
			margin: 0;
			text-align: center;
		} 
	`] 
})

export class CopyrightComponent {}