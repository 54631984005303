import {Component,Input,NgZone} from '@angular/core';
import {TranslatePipe} from 'ng2-translate';
import {RestService} from "../../services/restCalls";

@Component({
	selector: 'links-org-region',
	template:`
	<dt>{{region.name}}</dt>
	<dd>
		<a href="{{region.url}}" target="_blank">{{region.url}}</a> <span
			*ngIf="region.facebook"><br /> <a
			href="{{region.facebook}}" target="_blank">{{region.facebook}}</a></span>
	</dd>
	`,
	styles: [`
	 		dl {
	 	    	margin-left:20px;
	     	}
	 	dd {
	     	padding-bottom: 8px;
	     	margin-left:20px;
	     }

	     dd:last-child {
	     	padding-bottom: 0;
	     }
	 	`],
})
export class LinksOrgRegion {
	@Input() region:any;
}



@Component({
	selector: 'links-org-promoter',
	template:`
	<dt>{{promoter.name}}</dt>
	<dd>
		<a href="{{promoter.url}}" target="_blank">{{promoter.url}}</a>
		<span *ngIf="promoter.facebook"><br /> 
			<a href="{{promoter.facebook}}" target="_blank">{{promoter.facebook}}</a>
		</span>
	</dd>
	`
})
export class LinksOrgPromoter {
	@Input() promoter:any;
}



@Component({
	selector: 'links-org-festival',
	template:`
	<dt>{{festival.name}}</dt>
	<dd>
		<a href="{{festival.url}}" target="_blank">{{festival.url}}</a> 
		<span *ngIf="festival.facebook">
			<br /> <a href="{{festival.facebook}}" target="_blank">{{festival.facebook}}</a>
		</span>
	</dd>
	`
})
export class LinksOrgFestival {
	@Input() festival:any;
}


@Component({
	selector: 'links-org-states',
	template:`
		<dt>{{state.country}}</dt>
		<dd>{{state.name}}<br /> 
			<a href="{{state.url}}" target="_blank">{{state.url}}</a>
			<span *ngIf="state.facebook"><br /> 
				<a href="{{state.facebook}}" target="_blank">{{state.facebook}}</a>
			</span>
		</dd>
	`,
	styles: [`
	 		dl {
	 	    	margin-left:20px;
	     	}
	 	dd {
	     	padding-bottom: 8px;
	     	margin-left:20px;
	     }

	     dd:last-child {
	     	padding-bottom: 0;
	     }
 	`],
})
export class LinksOrgStates {
	@Input() state:any;
}


@Component({
	selector: 'links-org-nation',
	template:`
		<dt>{{nation.name}}</dt>
		<dd>
			<a href="{{nation.url}}" target="_blank">{{nation.url}}</a>
			<span *ngIf="nation.facebook"><br /> 
				<a href="{{nation.facebook}}" target="_blank">{{nation.facebook}}</a>
			</span>
			<dl *ngIf="nation.states">
				<links-org-states *ngFor="let state of nation.states" [state]="state"></links-org-states>
			</dl>
			<dl *ngIf="nation.promoter">
				<links-org-promoter *ngFor="let promoter of nation.promoter" [promoter]="promoter"></links-org-promoter>
			</dl>
			<dl *ngIf="nation.festival">
				<links-org-festival *ngFor="let festival of org.festival" [festival]="festival"></links-org-festival>
			</dl>
		</dd>
	`,
	styles: [`
	 		dl {
	 	    	margin-left:20px;
	     	}
	 	dd {
	     	padding-bottom: 8px;
	     	margin-left:20px;
	     }

	     dd:last-child {
	     	padding-bottom: 0;
	     }
	`],
	directives: [LinksOrgStates, LinksOrgPromoter, LinksOrgFestival]
})
export class LinksOrgNation {
	@Input() nation:any;
}




@Component({
	selector: 'links-org',
	template:`
		<dt>
			<span class='orgHeader'>{{org.org}}</span>
		</dt>
		<dd>
			<b *ngIf="org.org != org.name"> {{org.name}}<br /></b><a href="{{org.url}}" target="_blank">{{org.url}}</a>
			<dl *ngIf="org.region">
				<links-org-region *ngFor="let region of org.region" [region]="region"></links-org-region>
			</dl>
			<dl *ngIf="org.nation">
				<links-org-nation *ngFor="let nation of org.nation" [nation]="nation"></links-org-nation>
			</dl>
			<dl *ngIf="org.promoter">
				<links-org-promoter *ngFor="let promoter of org.promoter" [promoter]="promoter"></links-org-promoter>
			</dl>
			<dl *ngIf="org.festival">
				<links-org-festival *ngFor="let festival of org.festival" [festival]="festival"></links-org-festival>
			</dl>
		</dd>
	`,
	styles: [`
		dl {
	    	margin-left:20px;
    	}
	dd {
    	padding-bottom: 8px;
    	margin-left:20px;
    }

    dd:last-child {
    	padding-bottom: 0;
    }
	`],
	directives: [LinksOrgRegion, LinksOrgNation, LinksOrgPromoter, LinksOrgFestival]
})
export class LinksOrgComponent {
	@Input() org:any;
}




@Component({
    template: `
	    <div class='linksView'>	
			<h3>{{"LINKS"|translate}}</h3>
			<dl>
				<links-org *ngFor="let org of results" [org]="org"></links-org>
			</dl>
		</div>
	`,

    styles: [`
        .linksView {
    		overflow: scroll;
    		width: 100%;
    		height: 100%;
    		padding: 0 50px 60px 50px;
	    }
		.linksView .orgHeader {
    		font-size: large;
	    }
	
	    .linksView dl {
	    	margin-left:20px;
	    }
   `],
   directives: [LinksOrgComponent],
   pipes: [TranslatePipe]
})
export class LinksComponent {
	constructor( private _restService:RestService, private _ngZone:NgZone ) {}
	
	results:any;
	
	ngOnInit() {
		this._restService.fetchLinks()
			.subscribe( (data) => {
				this.results = data; 
				console.log( this.results);
				this._ngZone.run( () => {} );
			} );
	}
}

