import {Component} from '@angular/core';
import {TranslatePipe} from 'ng2-translate';

@Component({
    templateUrl: 'app/views/info/Acknowledgments.html',
    styles: [`
     		.ack {
         		top: 0;
         		bottom: 0;
         		left: 0;
         		right: 0;
         		width: 700px;
         		margin: 50px auto;
         	}
     	`],
	pipes: [TranslatePipe]
})
export class AckComponent {}
