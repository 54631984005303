import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Observer} from 'rxjs/Observer';
import 'rxjs/add/operator/share';

import {RestService} from "./restCalls";
	
@Injectable()
export class ParamsService {
	private _stats = {};
	private _orgs:any[] = [];
	private _countries:any[] = [];
	
	paramChange$: Observable<any>;
	private _paramObserver: Observer<any>;
	
	constructor( private _restService:RestService ) {
		this.paramChange$ = new Observable( (observer:any) => this._paramObserver = observer ).share();
		
		_restService.getParams().subscribe( data => {
			console.log( data );
	    	this._stats = data.stats;
	    	this._orgs = data.orgs;
	    	this._countries = data.countries;
	    	if( this._paramObserver ) this._paramObserver.next(data);
	    	this._restService.hackRefresh();
		} );
	}
	
	get numEntries(): number {
		return this._stats.hasOwnProperty( 'numEntries' ) ? this._stats['numEntries'] : 0; 
	}
	get numEvents(): number {
		return this._stats.hasOwnProperty( 'numEvents' ) ? this._stats['numEvents'] : 0; 
	}
	get numContests(): number {
		return this._stats.hasOwnProperty( 'numContests' ) ? this._stats['numContests'] : 0; 
	}
	get numMen(): number {
		return this._stats.hasOwnProperty( 'numMen' ) ? this._stats['numMen'] : 0; 
	}
	get numWomen(): number {
		return this._stats.hasOwnProperty( 'numWomen' ) ? this._stats['numWomen'] : 0; 
	}

	get maxYearMen():number {
		return this._stats.hasOwnProperty( 'maxYearMen' ) ? this._stats['maxYearMen'] : 0; 
	}
	get maxYearWomen():number {
		return this._stats.hasOwnProperty( 'maxYearWomen' ) ? this._stats['maxYearWomen'] : 0; 
	}
	get minYearMen():number {
		return this._stats.hasOwnProperty( 'minYearMen' ) ? this._stats['minYearMen'] : 0; 
	}
	get minYearWomen():number {
		return this._stats.hasOwnProperty( 'minYearWomen' ) ? this._stats['minYearWomen'] : 0; 
	}
	
	get countries(): any {
		return this._countries;
	}
	get orgs(): any {
		return this._orgs;
	}
	
	filteredOrgs( filter:string[] ):any {
		if( !filter || !this._orgs ) {
			return this._orgs;
		} else {
			let orgs = [];
			let len = this._orgs.length;
			for( var i=0; i<len; i++ ) {
				let org_id = this._orgs[i].org_id.toString();
				if( filter.indexOf(org_id) >= 0 ) orgs.push(this._orgs[i]);
			}
			return orgs;
		}
	}
	
	org_abbrev( org_id:number ):string {
		for( var i=0; i<this._orgs.length; i++ ) {
			if( this._orgs[i].org_id == org_id ) return this._orgs[i].org_abbrev;
		}
		return org_id+" not found";
	}
}