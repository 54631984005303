import {Component} from '@angular/core';
import {ActivatedRoute,ROUTER_DIRECTIVES} from '@angular/router';
import {Subject} from "rxjs";
import {TranslateService, TranslatePipe} from 'ng2-translate';
import {FormatMonthPipe,PrefixSuffixStrPipe,PrefixStrPipe} from "../../pipes/formatting";
import {RestService} from "../../services/restCalls";

@Component({
    templateUrl: 'app/views/media/Articles.html',
    styles: [`
        .articlesContainer {
    		width:90%;
    		max-width:1000px;
    		margin: 0 auto;
	    }
   		.articlesContainer dt {
			padding-left:30px;
	    }
		.articlesContainer dd {
			padding-left:60px;
	    }
	    .not-found {
	    	margin-left: 10px;
	    	color: #770000;
	    	font-weight: bold;
	    }
   `],
	directives: [ROUTER_DIRECTIVES],
    pipes: [TranslatePipe,FormatMonthPipe,PrefixSuffixStrPipe,PrefixStrPipe]
})
export class ArticlesComponent {
	private subscription:any;
	private list:any[] = [];
	private searchString:string = '';
	private showResults:boolean = false;
	private showError:boolean = false;

	private searchStream:Subject<any> = new Subject<any>();
	private go() {
		console.log( this.searchString );
		this.searchStream.next(this.searchString);
	}
	
	constructor( 
			private _route:ActivatedRoute,
			private _restService:RestService
	) {}
	
	ngOnInit() {
		this.subscription = this._route.params
			.map( (params) => {
				return this.searchString = params['match']
			} )
			.merge( this.searchStream )
			.flatMap( (data, i ) => {
				return this._restService.searchArticles(data);
			})
			.subscribe( (list:any[]) => {
				this.list = list;
				this.showResults = list.length > 0;
				this._restService.hackRefresh();
			} );
	}
	
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	private authors:any[];
	private len:number;
	
	private parseAuthors( authors ) {
		this.len = 0;
		if( authors ) {
			this.authors = authors.split('|');
			this.len = this.authors.length;
		}
		return this.len > 0;
	}
	
	clearList() {
		this.searchString = '';
		this.showResults = false;
	}
}
