import {Component} from '@angular/core';
import {TranslateService, TranslatePipe} from 'ng2-translate';
import {ParamsService} from "../../services/params";
import {LocalizeNumberPipe} from "../../pipes/formatting";
	
@Component({
    template: `
	    <div class='summary'>
			<h3>{{"STATS.DATABASE_STATISTCS"|translate}}</h3>
			<p >{{"STATS.SUMMARY"|translate: translationObject }}</p>
		</div>
    `,
    styles: [`
         .summary {
    		max-width: 1000px;
    		margin: 50px auto;
    		text-align: center;
    	}
    `],
    pipes: [TranslatePipe, LocalizeNumberPipe]
})
export class SummaryComponent {
	private subscription:any;
	private translationObject:any = {};
	
	constructor( private _paramsService:ParamsService ) {}
	
	ngOnInit() {
		this.subscription = this._paramsService.paramChange$.subscribe( () => {
				this.translationObject = {
					entries: this._paramsService.numEntries,
					events: this._paramsService.numEvents,
					contests: this._paramsService.numContests,
					men: this._paramsService.numMen,
					women: this._paramsService.numWomen
				};
			});
	}
	
	ngOnDestroy() {
		if( this.subscription ) this.subscription.unsubscribe();
	}
}
