import {Component, Input} from '@angular/core';
import {TranslatePipe} from 'ng2-translate';

@Component({
	selector: 'labeled-value',
    template:`
    <div *ngIf="showItem">
   		<span style='font-weight:bold'>{{label | translate}}</span> {{_value}}
    </div>
    `,
    pipes: [TranslatePipe],
})
export class LabeledValue {
	private showItem:boolean;
	private _value:string;

	@Input() set value( val:string ) {
		this.showItem = val != null && val.length > 0;
		this._value = val
	};
	@Input() label:string;
}