import {Component, Input} from '@angular/core';
import {Control} from '@angular/common';
import {ROUTER_DIRECTIVES} from '@angular/router';
import {Subject} from "rxjs";
import {CountrySelector} from "../widgets/countrySelector";
import {DataFilterSummary} from "../widgets/dataFilterSummary";
import {TranslatePipe} from 'ng2-translate';

@Component({
	selector: 'athlete-search-form',
    template: `
    	<div class='athlete-search-form'>
			<p *ngIf="showCountries">{{"ATHLETE_SEARCH.SUMMARY"|translate}}</p>
			<span >{{'ATHLETE_SEARCH.ATHLETE_BY_NAME'|translate}}</span>: 
			<input [(ngModel)]='data.match' (keyup.enter)="go()"/>
			<country-selector *ngIf="showCountries" [(ngModel)]="data.country_code"></country-selector>
			<button (click)="go()">{{'BUTTONS.FIND'|translate}}</button>
			&nbsp;&nbsp;
			<data-filter-summary></data-filter-summary>
			<span *ngIf="limit">{{'ATHLETE_SEARCH.RESULTS_LIMITED'|translate: {limit:1000} }}</span> 
			<span *ngIf="!showCountries" class='link' 
				style="float: right" 
					(click)="countrySearch()" >{{'ATHLETE_SEARCH.COUNTRY_SEARCH'|translate}}</span>
		</div>
    `,
    directives: [ROUTER_DIRECTIVES,DataFilterSummary,CountrySelector],
    pipes: [TranslatePipe],
    styles: [`
        .athlete-search-form {
    	 float: left;
	    }
    	select {
    		width:150px;
    		margin-top: 8px;
	    }
	`]
})
export class AthleteSearchForm {
	@Input() showCountries:boolean = false;
	@Input() limit:boolean;
	@Input() searchStream:Subject<any>;
	@Input() data = { match:'', country_code:'' };
	
	private countrySearch() {
		this.showCountries = true;
	}
	
	private go() {
		this.searchStream.next(this.data);
	}
}