import {Component} from '@angular/core';
import {ROUTER_DIRECTIVES} from '@angular/router';
import {DROPDOWN_DIRECTIVES} from 'ng2-bootstrap';
import {TranslatePipe} from 'ng2-translate';
import {SiteLogoComponent} from './siteLogo';

@Component({
    selector: 'musmem-menu',
    templateUrl: 'app/views/Menu.html',
    directives: [DROPDOWN_DIRECTIVES, ROUTER_DIRECTIVES, SiteLogoComponent],
    pipes: [TranslatePipe],
    styles: [`
    	.temp-router-outlet {
    		position: absolute;
    		top:50px;
    		bottom:50px;
    		left:0;
    		right:0;
    		overflow: hidden;
   			border: solid thin red;
    	}
    `]
})

export class TopMenuComponent {}