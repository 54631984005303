import {Component,Input} from '@angular/core';
import {TranslatePipe} from 'ng2-translate';

@Component({
	selector: 'athlete-bio',
    template: `
    	<div *ngIf="bio">
			<h3>{{"ATHLETE_HISTORY.BIO"|translate}}</h3>
	    	<div class='athlete-bio' [innerHTML]="bio"></div>
	    </div>
    `,
    styles: [`
		.athlete-bio {
    		padding-left:30px;
		}
    `],
    pipes: [TranslatePipe]
})
export class AthleteBio {
	@Input() bio:any;
}